import { NgForOf, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from '../../generic/spinner/spinner.component';
import { GetMetaversionFilteredThingsResult } from '../../models/backend/metaversion/get-things-request-body';
import { RolloutConfirmation } from '../../models/metaversion/confirm-deployment';

@Component({
  selector: 'app-deploy-filtered-things-dialog',
  standalone: true,
  imports: [NgIf, NgForOf, SpinnerComponent],
  templateUrl: './deploy-filtered-things-dialog.component.html',
  styleUrl: './deploy-filtered-things-dialog.component.scss',
})
export class DeployFilteredThingsDialogComponent {
  protected readonly modal = inject(NgbActiveModal);

  filteredThingsResult: GetMetaversionFilteredThingsResult | undefined;

  classicDeployment(): void {
    this.modal.close(RolloutConfirmation.CLASSIC);
  }

  progressiveDeployment(): void {
    this.modal.close(RolloutConfirmation.PROGRESSIVE);
  }

  cancel(): void {
    this.modal.close(RolloutConfirmation.CANCEL);
  }
}
