import { ThingType } from 'src/app/models/thingtype';
import { FirmwareType } from '../../models/backend/firmware/firmware-type';

export class FirmwareUtils {
  /**
   * Splits a firmware id into usable data
   * firmware id has the following format : <thingType>_<type>_<version>
   * @param firmwareId
   */
  public static splitFirmwareId(firmwareId: string): {
    thingType: ThingType;
    version: string;
    type: FirmwareType;
  } {
    const [thingType, type, ...version] = firmwareId.split('_');

    return {
      type: type as FirmwareType,
      thingType: thingType as ThingType,
      version: version.join('_'),
    };
  }
}
