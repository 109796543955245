import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { ThingListDisplay } from '../../../models/thing-list-display.model';
import { FirmwareDistributionComponent } from '../../../shared/firmware-distribution/firmware-distribution.component';

@Component({
  selector: 'app-group-of-thing-details-firmware-distribution',
  templateUrl: './group-of-thing-details-firmware-distribution.component.html',
  styleUrls: ['./group-of-thing-details-firmware-distribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FirmwareDistributionComponent],
})
export class GroupOfThingDetailsFirmwareDistributionComponent {
  readonly _totals = { wifi: 0, ui: 0 };

  _chartDataUi: ChartConfiguration['data'] = {
    labels: [],
    datasets: [],
  };

  _chartDataWifi: ChartConfiguration['data'] = {
    labels: [],
    datasets: [],
  };

  @Input() set things(things: ThingListDisplay[]) {
    this._totals.wifi = 0;
    this._totals.ui = 0;

    const wifiDistributionMap = new Map<string, number>();
    const uiDistributionMap = new Map<string, number>();

    things.forEach((thing) => {
      const wifi = thing.firmwareDetails?.wifi;
      if (wifi) {
        wifiDistributionMap.set(wifi, (wifiDistributionMap.get(wifi) ?? 0) + 1);
        this._totals.wifi++;
      }

      const ui = thing.firmwareDetails?.ui;
      if (ui) {
        uiDistributionMap.set(ui, (uiDistributionMap.get(ui) ?? 0) + 1);
        this._totals.ui++;
      }
    });

    this._chartDataUi = {
      labels: Array.from(uiDistributionMap.keys()),
      datasets: [
        {
          label: 'count',
          data: Array.from(uiDistributionMap.values()),
        },
      ],
    };
    this._chartDataWifi = {
      labels: Array.from(wifiDistributionMap.keys()),
      datasets: [
        {
          label: 'count',
          data: Array.from(wifiDistributionMap.values()),
        },
      ],
    };
  }
}
