<!-- Begin Page Content -->
<div class="container-fluid" *ngIf="job">
  <!-- Page Heading -->
  <div class="d-flex flex-column mb-4 gap-2" *ngIf="metaversionJob">
    <div class="d-sm-flex flex-wrap align-items-center justify-content-between">
      <h1 class="mb-0 text-gray-800">
        {{ jobId }}
      </h1>
      <div class="mb-0 text-gray-800" *ngIf="job.status === 'IN_PROGRESS'">
        <button
          class="btn btn-sm btn-primary shadow-sm btn-in-progress"
          (click)="refreshJob()"
        >
          <i class="fas fa-sync" aria-hidden="true"></i> Refresh
        </button>
        <button
          class="btn btn-sm btn-danger shadow-sm btn-in-progress"
          *canCancelDeployment
          (click)="cancelJob()"
        >
          <i class="fas fa-stop-circle" aria-hidden="true"></i> Stop Deployment
        </button>
      </div>
    </div>
    <div class="d-flex flex-row gap-2 flex-wrap">
      <div class="h2 d-flex flex-row gap-2 mb-0 flex-wrap">
        <ng-container [ngSwitch]="job.status">
          <span *ngSwitchCase="'COMPLETED'" class="badge badge-success">COMPLETED</span>
          <span *ngSwitchCase="'IN_PROGRESS'" class="badge badge-warning">IN_PROGRESS</span>
          <span *ngSwitchCase="'FAILED'" class="badge badge-danger">FAILED</span>
          <span *ngSwitchCase="'CANCELED'" class="badge badge-secondary">CANCELED</span>
        </ng-container>
        <ng-container>
          <app-job-type-badge
            [jobType]="metaversionJob.jobType"
            [versionFilterCount]="metaversionJob.targetedVersions"
            [block]="true"
          />
        </ng-container>
        <ng-container *ngIf="metaversionJob.progressiveRollout">
          <span class="badge badge-info">PROGRESSIVE ROLLOUT</span>
        </ng-container>
      </div>
      <h6 class="text-muted align-self-end mb-0">
        <app-audit-creator
          [action]="AuditAction.START"
          [type]="AuditType.DEPLOYMENT"
          [resourceId]="jobId"
          phrasing="started by"
        />&nbsp;
        <small>at <strong>{{ metaversionJob.dateStr }}</strong></small>
      </h6>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <div class="card shadow card-deploy">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            Deployed {{ sumDone }} out of {{ sumAll }} devices
          </h6>
        </div>
        <div class="card-body">
          <div class="progress" style="height: 30px" *ngIf="job">
            <div
              class="progress-bar bg-success"
              [ngClass]="{
                'progress-bar-striped progress-bar-animated':
                  this.sumInProgress > 0 || job.status === 'IN_PROGRESS',
              }"
              role="progressbar"
              aria-valuenow="successProgress"
              aria-valuemin="0"
              aria-valuemax="100"
              [style.width.%]="successProgress"
            ></div>
            <div
              class="progress-bar bg-danger progress-bar-striped progress-bar-animated"
              [ngClass]="{
                'progress-bar-striped progress-bar-animated':
                  this.sumInProgress > 0 || job.status === 'IN_PROGRESS',
              }"
              role="progressbar"
              aria-valuenow="failProgress"
              aria-valuemin="0"
              aria-valuemax="100"
              [style.width.%]="failProgress"
            ></div>
            <div
              class="progress-bar bg-secondary progress-bar-striped progress-bar-animated"
              [ngClass]="{
                'progress-bar-striped progress-bar-animated':
                  this.sumInProgress > 0 || job.status === 'IN_PROGRESS',
              }"
              role="progressbar"
              aria-valuenow="ignoredProgress"
              aria-valuemin="0"
              aria-valuemax="100"
              [style.width.%]="ignoredProgress"
            ></div>
          </div>
        </div>
      </div>
      <div class="row mb-4" *ngIf="job && job.jobProcessDetails">
        <div class="col">
          <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-info text-uppercase mb-1"
                  >
                    UPGRADE QUEUED
                  </div>
                  <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                    {{ this.sumQueued }}
                  </div>
                </div>
                <div class="col-auto">
                  <i
                    class="fas fa-layer-group fa-2x text-gray-300"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card border-left-warning shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                  >
                    UPGRADE IN PROGRESS
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ this.sumInProgress }}
                  </div>
                </div>
                <div class="col-auto">
                  <i
                    class="fas fa-sync-alt fa-2x text-gray-300 fa-spin"
                    [ngClass]="{ 'fa-spin': this.sumInProgress > 0 }"
                    aria-hidden="true"
                  >
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-info text-uppercase mb-1"
                  >
                    UPGRADE SUCCESSFUL
                  </div>
                  <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                    {{ this.sumSuccess }}
                  </div>
                </div>
                <div class="col-auto">
                  <i
                    class="fas fa-check-circle fa-2x text-gray-300"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card border-left-danger shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-danger text-uppercase mb-1"
                  >
                    UPGRADE FAILED
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ this.sumFail }}
                  </div>
                </div>
                <div class="col-auto">
                  <i
                    class="fas fa-times-circle fa-2x text-gray-300"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card border-left-secondary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                  >
                    UPGRADE IGNORED
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ this.sumIgnored }}
                  </div>
                </div>
                <div class="col-auto">
                  <i
                    class="fas fa-ban fa-2x text-gray-300"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row gap-4">
          <div
            *ngIf="metaversionJob?.targetedVersions as targetedVersions"
            class="card shadow mb-4 align-self-start"
          >
            <div class="card-header py-3">
              <h6
                class="m-0 font-weight-bold text-primary"
                style="display: inline-block"
              >
                Targeted to Firmware Versions
              </h6>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item p-1"
                  *ngFor="let versionFilterCount of targetedVersions"
                >
                  <strong class="fs-5">{{
                      versionFilterCount.filter.version
                    }}</strong>
                  <span class="badge text-bg-light">{{
                      versionFilterCount.filter.type
                    }}</span>
                  : {{ versionFilterCount.count }} devices
                </li>
              </ul>
            </div>
          </div>
          <div
            *ngIf="
              metaversionJob?.progressiveRollout
                ?.progressiveRolloutConfiguration as progressiveConfig
            "
            class="card shadow mb-4 align-self-start"
          >
            <div class="card-header py-3">
              <h6
                class="m-0 font-weight-bold text-primary"
                style="display: inline-block"
              >
                Progressive Rollout
              </h6>
            </div>
            <div class="card-body p-0">
              <table class="table mb-0">
                <tr>
                  <td>Devices per Day</td>
                  <td>
                    <span class="badge text-bg-success">{{
                        progressiveConfig.maximumPerMinute! * 60 * 24
                      }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Rollout over</td>
                  <td>
                    <span class="badge text-bg-primary">{{
                        computeEstimatedTime()
                      }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            *ngIf="
              metaversionJob?.progressiveRollout
                ?.abortConfiguration as abortConfig
            "
            class="card shadow mb-4 align-self-start"
          >
            <div class="card-header py-3">
              <h6
                class="m-0 font-weight-bold text-primary"
                style="display: inline-block"
              >
                Automatic Cancellation
              </h6>
            </div>
            <div class="card-body p-0">
              <table class="table mb-0">
                <tr>
                  <td>Minimum devices notified</td>
                  <td>
                    <span class="badge text-bg-success">{{
                        abortConfig.minNumberOfExecutedThings
                      }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="badge text-bg-danger">FAILED</span> threshold
                  </td>
                  <td>
                    <span class="badge text-bg-primary"
                    >{{ abortConfig.thresholdPercentage }}%</span
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="card shadow mb-4 flex-fill align-self-stretch">
            <div class="card-header py-3">
              <h6
                class="m-0 font-weight-bold text-primary"
                style="display: inline-block"
              >
                Firmwares To Deploy
              </h6>
              <div style="display: inline-block; width: 20px"></div>
            </div>
            <div class="card-body">
              <span>
                Metaversion :
                <a
                  *ngIf="parsedDocument?.nextFirmware?.version as version"
                  routerLink="/metaversions/{{ version }}"
                >{{ version }}</a
                >
              </span>
              <span class="ml-2">
                Job document :
                <a class="show-document" (click)="showDocument = !showDocument">
                  {{ showDocument ? "Hide" : "Show" }}
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-angle-up': showDocument,
                      'fa-angle-down': !showDocument,
                    }"
                    aria-hidden="true"
                  ></i>
                </a>
              </span>
              <pre class="hideable" [ngClass]="{ hidden: !showDocument }">{{
                  parsedDocument | json
                }}</pre>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="firmwareDistribution && firmwareDistributionTotals"
        class="card shadow mb-4"
      >
        <div class="card-header py-3">
          <h6
            class="m-0 font-weight-bold text-primary"
            style="display: inline-block"
          >
            Firmware Distribution
          </h6>
        </div>
        <div class="card-body">
          <app-firmware-distribution
            [totals]="firmwareDistributionTotals"
            [chartDataUi]="firmwareDistribution.ui"
            [chartDataWifi]="firmwareDistribution.wifi"
          ></app-firmware-distribution>
        </div>
      </div>
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row justify-content-between">
          <div class="form-row align-items-center">
            <h6 class="m-0 font-weight-bold text-primary col-auto">
              Devices List
            </h6>
            <div class="col-auto">Status:</div>
            <div class="col-auto">
              <select
                class="form-select"
                id="statusFilters"
                name="statusFilters"
                (change)="onChange($event)"
              >
                <option
                  [ngValue]="statusFilter"
                  *ngFor="let statusFilter of statusFilters"
                >
                  {{ statusFilter }}
                </option>
              </select>
            </div>
          </div>
          <div
            *ngIf="group$ | async as group"
            [ngbTooltip]="
              group.groupId && group.groupName
                ? 'This deployment was created from this custom group of things'
                : 'The group from which this deployment originated has been deleted and can no longer be accessed'
            "
          >
            <span class="font-weight-bold mr-2">
              <i class="fas fa-fw fa-sitemap mr-1" aria-hidden="true"></i>
              Group :
            </span>
            <ng-container
              *ngIf="group.groupId && group.groupName; else deletedGroup"
            >
              <a routerLink="/groups/{{ group.groupId }}">{{
                  group.groupName
                }}</a>
            </ng-container>
            <ng-template #deletedGroup>{{ group }}</ng-template>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table
              class="table"
              id="dataTable"
              *ngIf="devices; else elseBlock"
            >
              <thead>
                <tr>
                  <th scope="col">Thing Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Last Update</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let device of devices">
                  <td class="align-middle">
                    <a
                      *ngIf="splitSerial(device.thingArn ?? '') as serial"
                      class="a-link"
                      [routerLink]="['/things', serial]"
                    >
                      @if (device.nickname) {
                        <div class="d-flex flex-column">
                          <span>{{ device.nickname }}</span>
                          <small class="text-muted">{{ serial }}</small>
                        </div>
                      } @else {
                        <span>{{ serial }}</span>
                      }
                    </a>
                  </td>
                  <td class="align-middle">
                      <span
                        *ngIf="device.jobExecutionSummary"
                        class="badge badge-secondary"
                        [ngClass]="{
                          'badge-info':
                            device.jobExecutionSummary.status === 'QUEUED',
                          'badge-warning':
                            device.jobExecutionSummary.status === 'IN_PROGRESS',
                          'badge-success':
                            device.jobExecutionSummary.status === 'SUCCEEDED',
                          'badge-danger':
                            device.jobExecutionSummary.status === 'TIMED_OUT' ||
                            device.jobExecutionSummary.status === 'FAILED',
                        }"
                      >
                        {{ device.jobExecutionSummary.status }}
                      </span>
                  </td>
                  <td class="align-middle">
                    {{ device.jobExecutionSummary | displayDateDevice }}
                  </td>
                  <td class="align-middle">
                      <app-deployment-statistics *ngIf="device.statistics as statistics"
                                                 [statistics]="statistics"/>
                  </td>
                </tr>
              </tbody>
            </table>
            <ng-template #elseBlock>
              <div class="alert alert-primary" role="alert">No devices</div>
            </ng-template>
          </div>
          <div class="card-body">
            <button
              class="btn btn-primary float-left enabledButton"
              *ngIf="this.nextToken || (isJobSplit && relatedJobIds.length > 0)"
              (click)="getNextDevices()"
            >
              View More
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
