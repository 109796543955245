@if (additionalData() ?? {} | keyvalue; as entries) {
  @if (entries.length) {
    <ul>
      @for (entry of entries; track entry.key) {
        <li class="pl-3">
          <strong>{{ entry.key | replace: '_':' ' }}</strong> :
          @if (entry.key === 'version_targeted' && Array.isArray(entry.value)) {
            @if (typedVersionFilterCount(entry.value); as versionFilterCount) {
              <ul class="pl-3">
                <li *ngFor="let line of versionFilterCount">
                  <span class="fst-italic">{{ line.filter.version }}</span> ({{ line.filter.type }}) : {{ line.count }}
                  devices
                </li>
              </ul>
            }
          } @else if (entry.key === 'rollout_configuration' && entry.value && entry.value.constructor.name === "Object") {
            @if (typedRolloutConfiguration(entry.value); as rolloutConfig) {
              <ul class="pl-3">
                @if (rolloutConfig.progressiveRolloutConfiguration; as prc) {
                  <li>
                    <span class="fst-italic">Maximum Devices per Minute</span> : {{ prc.maximumPerMinute }}
                  </li>
                }
                @if (rolloutConfig.abortConfiguration; as abortConfig) {
                  <li>
                    Auto cancel
                    <ul class="pl-3">
                      <li>
                        <span class="fst-italic">Minimum devices notified</span>
                        : {{ abortConfig.minNumberOfExecutedThings }}
                      </li>
                      <li>
                        <span class="fst-italic">FAILED threshold</span> : {{ abortConfig.thresholdPercentage }}%
                      </li>
                    </ul>
                  </li>
                }
              </ul>
            }
          } @else if (Array.isArray(entry.value)) {
            @if (typedArray(entry.value); as array) {
              <ul class="pl-3">
                <li *ngFor="let line of array">
                  {{ line }}
                </li>
              </ul>
            }
          } @else {
            {{ entry.value }}
          }
        </li>
      }
    </ul>
  } @else {
    <span>-</span>
  }
}
