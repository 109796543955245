export abstract class RolloutUtils {
  static computeEstimatedTime(total: number, maximumPerMinute: number): string {
    const ratePerDay = maximumPerMinute * 60 * 24;
    if (total > ratePerDay) {
      const estimatedDays = (total / ratePerDay).toFixed(1); // rounded to 1st decimal
      return `${estimatedDays} days`;
    }

    const estimatedHours = ((total / ratePerDay) * 24).toFixed(1); // rounded to 1st decimal
    return `${estimatedHours} hours`;
  }
}
