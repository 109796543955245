<ng-container *ngIf="thingDeploymentHistory$ | async as history; else loader">
  <ng-container *ngIf="history.length; else noHistory">
    <app-ngx-table-with-query-params-persistence
      [data]="history"
      [columns]="columns"
    >
      <ng-template let-untypedItem>
        <!-- Allows IDEs to correctly infer the object type to provide completion and checks -->
        <ng-container *ngIf="typedHistoryItem(untypedItem) as item">
          <td class="align-middle">
            <div class=" vstack gap-1">
              <h3 class="mb-1">
                <a [href]="'/metaversions/' + item.metaversion.id">
                  {{ item.metaversion.id }}
                </a>
              </h3>
              <small>started at <strong>{{ item.deployment.metaversionJob.dateStr }}</strong></small>
            </div>
          </td>
          <td class="align-middle">
            <h3>
              <span
                *ngIf="
                  item.deployment.jobExecution.jobExecutionSummary
                    ?.status as status
                "
                class="badge badge-secondary"
                [ngClass]="{
                  'badge-info': status === 'QUEUED',
                  'badge-warning': status === 'IN_PROGRESS',
                  'badge-success': status === 'SUCCEEDED',
                  'badge-danger': status === 'TIMED_OUT' || status === 'FAILED',
                }"
              >
                {{ status }}
              </span>
            </h3>
            <span>
              Last updated at <br />
              <strong>{{
                item.deployment.jobExecution.jobExecutionSummary?.lastUpdatedAt
                  | date: "YYYY-MM-dd HH:mm:ss"
              }}</strong>
            </span>
          </td>
          <td class="align-middle">
            <div class="d-flex gap-1 flex-column">
              <div>
                <span
                  class="badge"
                  *ngIf="item.deployment.job.status as status"
                  [ngClass]="{
                    'badge-success': status === 'COMPLETED',
                    'badge-warning': status === 'IN_PROGRESS',
                    'badge-secondary': status === 'CANCELED',
                  }"
                >
                  {{ status }}
                </span>
              </div>
              <ng-container *ngIf="item.deployment.job.lastUpdatedAt as completedAt">
                <small>
                  Last updated at<br/>
                  <strong>
                    {{ completedAt | date: "YYYY-MM-dd HH:mm:ss" }}
                  </strong>
                </small>
              </ng-container>
            </div>
          </td>
          <td class="align-middle">
            <div class="d-flex flex-column gap-2">
              <app-job-type-badge
                [jobType]="item.deployment.metaversionJob.jobType"
                [versionFilterCount]="item.deployment.metaversionJob.targetedVersions"
              />
              <ng-container *ngIf="item.group as group">
                <a class="link-underline" [href]="'/groups/' + group.id"
                  >{{ group.name }} ({{ group.numberOfThings }})</a
                >
              </ng-container>
            </div>
          </td>
          <td class="align-middle">
            <ng-container
              *ngFor="
                let firmware of [
                  item.metaversion.uiFirmware,
                  item.metaversion.wifiFirmware,
                ]
              "
            >
              <ng-container *ngIf="firmware | formatFirmwareId as idParts">
                <a [href]="'/firmwarefile/' + firmware" class="d-inline-block">
                  <small class="badge badge-light">{{ idParts.type }}</small>
                  {{ idParts.version }}
                </a>
              </ng-container>
            </ng-container>
          </td>
          <td class="align-middle">
            <app-deployment-statistics *ngIf="item.deployment.statistics as statistics"
                                       [statistics]="statistics"/>
          </td>
          <td class="align-middle">
            <a
              class="btn btn-eye"
              [href]="'/deployments/' + item.deployment.job.jobId"
            >
              <i class="fas fa-eye"></i>
            </a>
          </td>
        </ng-container>
      </ng-template>
    </app-ngx-table-with-query-params-persistence>
  </ng-container>
  <ng-template #noHistory>
    <div class="mt-5 pt-5 d-flex justify-content-center">
      <h1>No history</h1>
    </div>
  </ng-template>
</ng-container>

<ng-template #loader>
  <app-spinner />
</ng-template>
