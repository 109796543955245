import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration } from 'date-fns/formatDuration';
import { intervalToDuration } from 'date-fns/intervalToDuration';

@Pipe({
  name: 'HumanizeDuration',
  standalone: true,
})
export class HumanizeDurationPipe implements PipeTransform {
  transform(valueInMs: number): unknown {
    const duration = intervalToDuration({
      end: valueInMs,
      start: 0,
    });
    return formatDuration(duration);
  }
}
