import { inject, Injectable } from '@angular/core';
import { DescribeThingGroupCommandOutput } from '@aws-sdk/client-iot';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AwsService } from '../lib/aws.service';
import { CreateDeploymentOptions } from '../models/backend/deployment/create-deployment-request';
import { CreateDeploymentResponse } from '../models/backend/deployment/create-deployment-response';
import { CriteriaKey } from '../models/firmware';
import { MetaVersion } from '../models/metaversion/meta-version';
import { ThingGroup } from '../models/thingtype';
import { DeploymentsService } from './backend/services/deployments/deployments.service';

@Injectable({
  providedIn: 'root',
})
export class DeployService {
  readonly awsService = inject(AwsService);
  readonly deploymentsService = inject(DeploymentsService);

  static buildThingGroupName(
    thingType: string,
    criteriaKey: CriteriaKey,
  ): string {
    return `${thingType}_${criteriaKey}`.replace(/\./g, '-');
  }

  /**
   * Checks if the given metaversion is ready to create a Job for the deployment.<br/>
   * Returns an observable that will emit once, of all the Thing Groups associated with the metaversion are fetched ('described').
   * The s3CriteriaKeys of each firmware is used to determine the Thing Groups names and fetch them
   *
   * @throws an error if the statusCode of the response is a 404, which means the Thing Group doesn't exist
   * @param metaVersion the metaversion we want to deploy
   */
  public prepareJob(metaVersion: MetaVersion): Observable<ThingGroup[]> {
    const brandAreas = metaVersion.getFirmwareBrandArea();
    if (!brandAreas) {
      throw new Error(
        'You cannot deploy a version with distinct brand-area attribute per firmware!',
      );
    }

    // Ui firmware contains the criteria keys
    // but if there isn't any ui firmware, get the wifi ones
    // which should be only the default "NA_NA_NA_NA"
    // because wifi firmwares don't handle (yet) range/cmmf/technical increment/brand area
    const s3CriteriaKeys = metaVersion.getS3CriteriaKeys();
    if (s3CriteriaKeys.length === 0) {
      throw new Error('No Firmware has any associated file');
    }

    return combineLatest(
      s3CriteriaKeys.map((s3CriteriaKey) => {
        const _groupName = DeployService.buildThingGroupName(
          metaVersion.thingType,
          s3CriteriaKey,
        );
        return this.describeAndMapThingGroup(_groupName, s3CriteriaKey);
      }),
    );
  }

  describeAndMapThingGroup(
    thingGroupName: string,
    criteriaKey: CriteriaKey,
  ): Observable<ThingGroup> {
    return this.awsDescribeThingGroup(thingGroupName).pipe(
      map(
        (describeThingGroupResult) =>
          new ThingGroup(
            thingGroupName,
            describeThingGroupResult.thingGroupArn,
            criteriaKey,
            describeThingGroupResult.status,
          ),
      ),
    );
  }

  startJob(
    metaVersion: MetaVersion,
    options: CreateDeploymentOptions,
  ): Observable<CreateDeploymentResponse> {
    return this.deploymentsService.startDeploymentJob(metaVersion.id!, options);
  }

  private awsDescribeThingGroup(
    thingGroupName: string,
  ): Observable<DescribeThingGroupCommandOutput> {
    return from(this.awsService.iot()).pipe(
      switchMap((iot) => iot.describeThingGroup({ thingGroupName })),
    );
  }
}
