import { NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VersionFilter } from '../../models/backend/metaversion/deployment-version-filter';

@Component({
  selector: 'app-version-filter-dialog',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgForOf],
  templateUrl: './version-filter-dialog.component.html',
  styleUrl: './version-filter-dialog.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionFilterDialogComponent {
  private readonly modal = inject(NgbActiveModal);
  private readonly toastrService = inject(ToastrService);

  rows: FormGroup[] = [];
  formGroup = new FormGroup({});

  constructor() {
    this.addRow();
  }

  cancel(): void {
    this.modal.close([]);
  }

  removeRow(index: number): void {
    this.rows.splice(index, 1);
    this.formGroup.removeControl(index.toString());
  }

  addRow(): void {
    const formGroup = new FormGroup({
      version: new FormControl<string | undefined>(
        undefined,
        Validators.required,
      ),
      type: new FormControl<string | undefined>('ui', Validators.required),
    });
    this.rows.push(formGroup);

    this.formGroup = new FormGroup(Object.fromEntries(this.rows.entries()));
  }

  confirm(): void {
    if (this.formGroup.invalid) {
      this.toastrService.error('Filters empty');
      return;
    }

    this.modal.close(Object.values(this.formGroup.value) as VersionFilter[]);
  }
}
