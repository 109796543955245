@if (totals().ui + totals().wifi > 0) {
  <div class="row">
    <div class="col" *ngIf="chartDataUi().datasets.length">
      <h2 class="text-center">UI firmwares ({{ totals().ui }})</h2>
      <canvas
        baseChart
        type="doughnut"
        class="chart"
        [data]="chartDataUi()"
        [options]="_chartOptions"
      ></canvas>
    </div>
    <div class="col" *ngIf="chartDataWifi().datasets.length">
      <h2 class="text-center">WIFI firmwares ({{ totals().wifi }})</h2>
      <canvas
        baseChart
        type="doughnut"
        class="chart"
        [data]="chartDataWifi()"
        [options]="_chartOptions"
      ></canvas>
    </div>
  </div>
} @else {
  <div class="w-100">
    <h2 class="text-center">No data</h2>
  </div>
}
