<div class="modal-body">
  <h2>Confirmation</h2>

  <p>You are upgrading :</p>
  <ul>
    <li *ngFor="let countDetail of deployCount.details">
        <span>
          <strong>{{ countDetail.count }}</strong> {{ countDetail.label }}
        </span>
      <ul *ngIf="countDetail.criteriaEntries">
        <li *ngFor="let criteriaEntry of countDetail.criteriaEntries">
          <strong>{{ criteriaEntry[0] }}</strong> : {{ criteriaEntry[1] }}
        </li>
      </ul>
    </li>
  </ul>
  Total : <strong>{{ deployCount.totalCount }}</strong> devices.
  <hr />
  <p>Are you sure ?</p>
  <div class="d-flex justify-content-end gap-2">
    <button (click)="cancel()" class="btn btn-secondary">
      Cancel
    </button>
    <button
      (click)="progressiveDeployment()"
      class="btn btn-success button-model"
    >
      Progressive rollout
    </button>
    <button
      (click)="classicDeployment()"
      class="btn btn-primary button-model"
    >
      Classic rollout
    </button>
  </div>
</div>
