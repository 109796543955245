import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JobType } from '@common/models/job-type';
import { VersionFilterCount } from '@common/models/version-filter-count';

@Component({
  selector: 'app-job-type-badge',
  templateUrl: './job-type-badge.component.html',
  styleUrls: ['./job-type-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobTypeBadgeComponent {
  @Input() jobType?: JobType;
  @Input() versionFilterCount?: VersionFilterCount[];
}
