<div class="container-fluid">
  <div *ngIf="metaVersion; else elseBlock">
    <div class="card mb-2">
      <div class="card-header d-inline-flex align-items-baseline">
        <h2 class="text-primary m-0">Metaversion</h2>
        <app-audit-creator
          [type]="AuditType.METAVERSION"
          [resourceId]="metaVersionId"
        />
      </div>
      <div class="card-body">
        <table
          class="table mb-0"
          aria-describedby="Table showing metaversion base information"
        >
          <tbody>
            <tr>
              <th scope="col">Meta Version</th>
              <td>{{ metaVersion.id }}</td>
            </tr>
            <tr>
              <th scope="col">Thing Type</th>
              <td>{{ metaVersion.thingType }}</td>
            </tr>
            <tr>
              <th scope="col">Files</th>
              <td >
                @for (firmware of metaversionFirmwares; track firmware.id) {
                  <span *canSeeFirmwareDetails="false">{{ firmware | displayFirmwareIdAndNumberOfFiles }}</span>
                  <a *canSeeFirmwareDetails="true"
                    [routerLink]="['/firmwarefile', firmware.id]">
                    {{ firmware | displayFirmwareIdAndNumberOfFiles }}
                  </a>
                  @if ($index < metaversionFirmwares.length - 1) {
                    {{"-"}}
                  }
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="metaVersion.uiFirmware" class="card mb-2">
      <div class="card-header">
        <h2 class="text-primary m-0 d-inline-block">
          <span *canSeeFirmwareDetails="false">Firmware UI</span>
          <a *canSeeFirmwareDetails="true" [routerLink]="['/firmwarefile', metaVersion.uiFirmware.id]">Firmware UI</a>
        </h2>
        @if (metaVersion.uiFirmware.versionFlag === VersionFlag.OFFICIAL){
          <span class="badge text-bg-success ml-2 align-top">Official</span>
        }
      </div>
      <div class="card-body">
        <app-firmware-table
          [firmware]="metaVersion.uiFirmware"
          [shouldDisplayRange]="shouldDisplayRange"
          [shouldDisplayCmmf]="shouldDisplayCmmf"
          [shouldDisplayIndice]="shouldDisplayIndice"
          [shouldDisplayBrandArea]="shouldDisplayBrandArea"
        ></app-firmware-table>
      </div>
    </div>
    <div *ngIf="metaVersion.wifiFirmware" class="card mb-2">
      <div class="card-header">
        <h2 class="text-primary m-0 d-inline-block">
          <span *canSeeFirmwareDetails="false">Firmware WIFI</span>
          <a *canSeeFirmwareDetails="true" [routerLink]="['/firmwarefile', metaVersion.wifiFirmware.id]">Firmware WIFI</a>
        </h2>
        @if (metaVersion.wifiFirmware.versionFlag === VersionFlag.OFFICIAL){
          <span class="badge text-bg-success ml-2 align-top">Official</span>
        }
      </div>
      <div class="card-body">
        <app-firmware-table
          [firmware]="metaVersion.wifiFirmware"
        ></app-firmware-table>
      </div>
    </div>
    <div class="card mb-2" *canDeployWithCriteria>
      <div class="card-header">
        <h2 class="text-primary m-0">Deploy</h2>
      </div>
      <div class="card-body">
        <form *ngIf="thingGroupsExist$">
          <div class="form-group d-flex flex-row align-items-center mb-0">
            <label for="deployAttribute" class="text-nowrap mr-3 mb-0"
              >Deploy Attribute</label
            >
            <input
              class="form-control"
              id="deployAttribute"
              name="deployAttributes"
              type="text"
              [value]="deployAttribute"
              readonly
              disabled
            />
            <button
              class="btn btn-primary create-button"
              (click)="prepareDeployment()"
              [disabled]="!massDeployRole || (thingGroupsExist$ | async)"
            >
              <ng-container *ngIf="!preparing">
                Prepare deployment
              </ng-container>
              <ng-container *ngIf="preparing">
                <span class="text-nowrap"
                  ><app-spinner-small></app-spinner-small> Preparing…</span
                >
              </ng-container>
            </button>
            <button
              class="btn btn-success create-button"
              (click)="startDeployment()"
              [disabled]="
                !massDeployRole ||
                (thingGroupsExist$ | async) === false ||
                deploying
              "
            >
              Start deployment
              <app-spinner-small *ngIf="deploying"></app-spinner-small>
            </button>
            <button
              class="btn btn-outline-success create-button"
              (click)="startDeploymentForThingsWithVersion()"
              [disabled]="
                !massDeployRole ||
                (thingGroupsExist$ | async) === false ||
                deploying
              "
            >
              Start Targeted Deployment
              <app-spinner-small *ngIf="deploying"></app-spinner-small>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="card mb-2" *canListDeployments>
    <div class="card-header">
      <form
        class="form-inline"
        [formGroup]="formGroupJobsFilters"
        (ngSubmit)="filterJobs()"
      >
        <h2 class="m-0 font-weight-bold text-primary">Deployments</h2>
        <div class="form-row">
          <div class="col-auto ml-3 d-flex">
            <div class="form-check form-switch mb-0">
              <input
                type="checkbox"
                id="multiOnly"
                name="multiOnly"
                class="form-check-input"
                formControlName="multiOnly"
                (change)="submitButton.click()"
              />
              <label class="form-check-label" for="multiOnly">
                Multiple targets only
              </label>
            </div>
          </div>
          <div class="col-auto ml-3">
            <button
              class="btn btn-primary"
              type="reset"
              (click)="resetFilters()"
            >
              Reset filters
            </button>
          </div>
        </div>
        <button type="submit" hidden #submitButton></button>
      </form>
    </div>
    <div class="card-body" *ngIf="jobs$ | async as jobs; else loading">
      <app-jobs-table
        [jobs]="jobs"
        [resetFilters]="resetTableFilters"
      ></app-jobs-table>
    </div>
  </div>

  <ng-template #loading>
    <app-spinner></app-spinner>
  </ng-template>
  <ng-template #elseBlock>
    <div class="alert alert-primary" *ngIf="!isLoading; else loading">
      Invalid firmware
    </div>
  </ng-template>
</div>

<ng-template #dialogNoThingGroup let-modal>
  <div class="modal-body">
    <h2>MetaVersion can't be deployed</h2>
    <hr />
    <h5 class="font-weight-bold text-danger">
      Deploying a MetaVersion is strict.
    </h5>
    <p>
      Connect all the devices you intend to deploy this MetaVersion to before
      deploying it.
    </p>
    <p class="font-weight-bold">
      At least one device must have been connected to the IoT, for each
      combination of CMMF / Range / Technical Increment in the Firmwares.
    </p>
    <hr />
    <button
      (click)="modal.close(true)"
      class="btn btn-danger float-right button-model"
    >
      close
    </button>
  </div>
</ng-template>
