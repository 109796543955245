import { inject, Injectable } from '@angular/core';

import {
  Job,
  ListJobExecutionsForJobCommandOutput,
  ListJobsCommandInput,
  ListJobsCommandOutput,
} from '@aws-sdk/client-iot';
import { JobExecutionStatus } from '@aws-sdk/client-iot/dist-types/models/models_1';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import CONFIG from '../../config';
import { AwsService } from '../lib/aws.service';
import { GroupOfThings } from '../models/Group-of-things.model';
import { MetaVersionJob } from '../models/meta-version-job.model';
import { DatabaseService } from './database.service';
import { GroupsOfThingsService } from './groups-of-things.service';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  private readonly awsService: AwsService = inject(AwsService);
  private readonly groupsService: GroupsOfThingsService = inject(
    GroupsOfThingsService,
  );
  private readonly databaseService: DatabaseService = inject(DatabaseService);

  listJobs(params: ListJobsCommandInput): Observable<ListJobsCommandOutput> {
    return from(this.awsService.iot()).pipe(
      switchMap((iot) =>
        iot.listJobs({
          maxResults: 250,
          ...params,
        }),
      ),
    );
  }

  async getJob(jobId: string): Promise<Job> {
    const res = await (await this.awsService.iot()).describeJob({ jobId });
    if (!res.job) {
      throw new Error('Missing job!');
    }
    return res.job;
  }

  async cancelJob(jobId: string): Promise<void> {
    await (await this.awsService.iot()).cancelJob({ jobId });
  }

  async getJobDocument(jobId: string): Promise<string> {
    const res = await (await this.awsService.iot()).getJobDocument({ jobId });
    if (!res.document) {
      throw new Error('Missing job document!');
    }
    return res.document;
  }

  async listDevicesForJob(
    jobId: string,
    status?: JobExecutionStatus,
    nextToken?: string,
  ): Promise<ListJobExecutionsForJobCommandOutput> {
    return (await this.awsService.iot()).listJobExecutionsForJob({
      jobId,
      status,
      maxResults: 250,
      nextToken,
    });
  }

  /**
   * Fetches the groupId associated with a job in dynamo.
   * If the group still exists, also fetches its details.
   *
   * @param jobId the job id to look for
   * @return <ul><li> null if no group is associated with this job</li>
   *  <li>a string containing `[deleted]{groupName}` if the group no longer exists</li>
   *  <li>The group details if a linked group exists</li></ul>
   */
  public getGroupForJob(
    jobId: string,
  ): Observable<GroupOfThings | string | null> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.get({
          TableName: CONFIG.metaversionJobsTable,
          Key: { jobId },
        }),
      ),
      switchMap((response) => {
        if (
          !response.Item?.groupId ||
          typeof response.Item.groupId !== 'string'
        ) {
          // No group, return null
          return of(null);
        }

        if (response.Item.groupId.startsWith('[deleted]')) {
          // DeletedGroup, return the name stored in groupId field
          return of(response.Item.groupId);
        }

        // Existing group, fetch its details
        return this.groupsService.getGroup(response.Item.groupId);
      }),
    );
  }

  /**
   * Returns the MetaversionJob associated with the given job
   * @param jobId the job id associated to the metaversion
   */
  getMetaversionForJob(jobId: string): Promise<MetaVersionJob | undefined> {
    return lastValueFrom(
      this.databaseService
        .getMetaversionForJobs([jobId])
        .pipe(
          map(
            (metaVersionJobs: MetaVersionJob[]): MetaVersionJob =>
              metaVersionJobs[0],
          ),
        ),
    );
  }
}
