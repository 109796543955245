import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DescribeThingCommandOutput } from '@aws-sdk/client-iot';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConsentsService } from '../api/backend/services/consents/consents.service';
import { ThingsService } from '../api/backend/services/things/things.service';
import { DatabaseService } from '../api/database.service';
import { GroupsOfThingsService } from '../api/groups-of-things.service';
import { ProductsService } from '../api/products/products.service';
import { RegistryService } from '../api/registry.service';
import { DeleteThingComponent } from '../dialogs/delete-thing/delete-thing.component';
import { AddThingToGroupComponent } from '../groups-of-things/groups-of-things-list/add-thing-to-group/add-thing-to-group.component';
import { RemoveThingFromGroupComponent } from '../groups-of-things/groups-of-things-list/remove-thing-from-group/remove-thing-from-group.component';
import { DeviceMetaConsents } from '../models/device-meta-consents';
import { GroupOfThings } from '../models/Group-of-things.model';
import { Product } from '../models/product';
import { Shadow } from '../models/shadow';
import { MacAddress, ThingData } from '../models/thingtype';
import { NotificationService } from '../shared/notification.service';
import { FirmwareService } from '../api/backend/services/firmware/firmware.service';
import { Firmware } from '../models/firmware';
import { thingTypeFromThingName } from '../utils/thing.utils';

@Component({
  selector: 'app-thing',
  templateUrl: './thing.component.html',
  styleUrls: ['./thing.component.css'],
})
export class ThingComponent implements OnInit {
  protected readonly AuditType = AuditType;
  protected readonly AuditAction = AuditAction;

  thingName?: string | null;
  thing?: DescribeThingCommandOutput;
  macAddress?: MacAddress;
  attributeKeys?: string[];
  consents$!: Observable<DeviceMetaConsents>;
  groups$?: Observable<GroupOfThings[]>;
  creationDate?: number;
  product?: Product;

  shadow: Shadow = new Shadow();
  refreshGroups$ = new BehaviorSubject<void>(void 0);
  firmwares$ = new BehaviorSubject<Record<string, Firmware>>({});
  editingNickname = false;
  editNicknameLoading = false;
  nicknameControls = new FormControl('', [
    Validators.maxLength(100),
    Validators.pattern('[a-zA-Z0-9_.,@/:#-]*'),
  ]);

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly thingService: RegistryService,
    private readonly databaseService: DatabaseService,
    private readonly modal: NgbModal,
    private readonly groupsService: GroupsOfThingsService,
    private readonly thingBackendService: ThingsService,
    private readonly consentsService: ConsentsService,
    private readonly productsService: ProductsService,
    private readonly firmwareService: FirmwareService,
  ) {}

  async ngOnInit(): Promise<void> {
    // Subscription Method
    this.activatedRoute.paramMap.subscribe((params) => {
      this.thingName = params.get('deviceId');
      this.refresh()
        .catch((err: Error) =>
          this.notificationService.showError(err.message, err),
        )
        .then(() => this.getGroups());

      this.fetchDeviceInfos().catch((err: Error) =>
        this.notificationService.showError(err.message, err),
      );
    });
  }

  refreshGroups(): void {
    this.refreshGroups$.next();
  }

  refresh(): Promise<[void, void]> {
    this.thing = undefined;
    this.shadow = new Shadow();

    if (!this.thingName) {
      return Promise.reject(new Error('Missing thing name'));
    }

    this.consents$ = this.consentsService.getConsents(this.thingName);

    return Promise.all([
      this.refreshThing(this.thingName),
      this.refreshShadow(this.thingName),
    ]);
  }

  refreshThing(thingName: string): Promise<void> {
    return this.thingService.getThing(thingName).then(
      (res) => {
        this.thing = res;
        if (this.thing?.attributes) {
          this.nicknameControls.setValue(this.thing?.attributes?.nickname);
          this.attributeKeys = Object.keys(this.thing.attributes)
            .filter((_) => !_.startsWith('fw_'))
            .filter((_) => _ !== 'customGroups')
            .sort();
          this.productsService
            .getProductByRange(this.thing.attributes.range)
            .subscribe((product) => {
              this.product = product;
            });
        }
      },
      (e) => {
        this.notificationService.showError('Thing not found', e);
      },
    );
  }

  private refreshShadow(thingName: string): Promise<void> {
    return firstValueFrom(
      this.thingBackendService.getThingShadow(thingName),
    ).then(
      (res) => {
        this.shadow = res!;
        const thingType = thingTypeFromThingName(thingName);
        const firmwaresIds = this.getFirmwaresIds(res, thingType);

        if (firmwaresIds.length) {
          this.fetchFirmwares(firmwaresIds).subscribe((firmwares) => {
            this.firmwares$.next(firmwares);
          });
        }
      },
      (e) => {
        this.notificationService.showError('Thing shadow not found', e);
      },
    );
  }

  async fetchDeviceInfos(): Promise<void> {
    if (!this.thingName) {
      this.notificationService.showError('Missing thing name');
      return;
    }

    const thingData = ThingData.from(this.thingName);
    const device = await this.databaseService.getDevice(thingData.serialNumber);
    this.macAddress = device.macAddress;
    this.creationDate = device.creationDate;
  }

  openDeleteDialog(): void {
    const componentInstance =
      this.modal.open(DeleteThingComponent).componentInstance;
    componentInstance.thingName = this.thingName;
    componentInstance.macAddress = this.macAddress;
  }

  addThingToAGroup(thing?: DescribeThingCommandOutput): void {
    if (thing == null) {
      return;
    }

    const modal = this.modal.open(AddThingToGroupComponent, {
      backdrop: 'static',
      centered: true,
    });

    modal.componentInstance.thing = ThingData.fromDescribeResponse(thing);

    modal.closed.subscribe((refresh: ThingData) => {
      if (refresh != null) {
        this.refresh().then(() => this.refreshGroups());
      }
    });
  }

  removeThingFromGroup(
    group?: GroupOfThings,
    thing?: DescribeThingCommandOutput,
  ): void {
    if (thing == null || group == null) {
      return;
    }

    const modal = this.modal.open(RemoveThingFromGroupComponent, {
      backdrop: 'static',
      centered: true,
    });

    modal.componentInstance.group = group;
    modal.componentInstance.thing = ThingData.fromDescribeResponse(thing);

    modal.closed.subscribe(async (refresh) => {
      if (refresh) {
        this.refreshGroups();
        await this.refresh();
      }
    });
  }

  private getGroups(): void {
    this.groups$ = this.refreshGroups$.pipe(
      switchMap(() => {
        if (this.thing?.attributes?.customGroups) {
          return this.groupsService
            .getGroupsForThing(this.thing?.attributes.customGroups)
            .pipe(
              catchError((err) => {
                this.notificationService.showError(
                  `Error fetching thing groups : ${err.message}`,
                  err,
                );
                return of([]);
              }),
            );
        }
        return of([]);
      }),
    );
  }

  saveNickname(): void {
    if (!this.thingName || !this.macAddress) {
      this.notificationService.showError(`No thingname or macaddess provided`);
      return;
    }

    const nickname = this.nicknameControls.value || null;
    this.thingBackendService
      .updateNickname(this.thingName, this.macAddress, nickname)
      .subscribe(async () => {
        this.editingNickname = false;
        await this.refreshThing(this.thingName!);
      });
  }

  cancelNameEdit(): void {
    this.nicknameControls.reset(this.thing?.attributes?.nickname);
    this.editingNickname = false;
  }

  private getFirmwaresIds(shadow: Shadow, thingTypeName: string): string[] {
    const reportedFirmwareIds = shadow
      .reportedFirmware()
      ?.files.map((file) => Shadow.getFirmwareIdFromFile(file, thingTypeName));
    const reportedNextFirmwareIds = shadow
      .reportedNextFirmware()
      ?.files.map((file) => Shadow.getFirmwareIdFromFile(file, thingTypeName));
    const desiredNextFirmwareIds = shadow
      .desiredNextFirmware()
      ?.files.map((file) => Shadow.getFirmwareIdFromFile(file, thingTypeName));

    return [
      ...(reportedFirmwareIds ?? []),
      ...(reportedNextFirmwareIds ?? []),
      ...(desiredNextFirmwareIds ?? []),
    ];
  }

  private fetchFirmwares(ids: string[]): Observable<Record<string, Firmware>> {
    return this.firmwareService.getFirmwares(ids).pipe(
      map((firmwares) =>
        firmwares.reduce(
          (agg: Record<string, Firmware>, firmware: Firmware) => ({
            ...agg,
            [firmware.id]: firmware,
          }),
          {},
        ),
      ),
    );
  }
}
