import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { VersionFlag } from '@common/models/version-flag.enum';

@Pipe({
  name: 'displayVersionFlag',
  standalone: true,
})
export class DisplayVersionFlagPipe implements PipeTransform {
  constructor(private readonly titlecasePipe: TitleCasePipe) {}

  transform(versionFlag: VersionFlag | undefined): string | undefined {
    if (versionFlag && versionFlag in VersionFlag) {
      return this.titlecasePipe.transform(versionFlag).replace('_', ' ');
    }

    return versionFlag;
  }
}
