import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { Columns } from 'ngx-easy-table';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ThingsService } from '../../api/backend/services/things/things.service';
import {
  ThingDeployment,
  ThingDeploymentHistory,
} from '../../models/backend/thing/thing-deployment';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-deployment-history',
  templateUrl: './deployment-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeploymentHistoryComponent implements OnInit {
  private readonly thingService = inject(ThingsService);
  private readonly notificationService = inject(NotificationService);

  @Input() thingname?: string;

  thingDeploymentHistory$?: Observable<ThingDeploymentHistory>;

  columns: Columns[] = [
    { key: 'metaversion', title: 'Metaversion', width: '7%' },
    { key: 'status', title: 'Deployment Status', width: '5%' },
    { key: 'job', title: 'Job Status', width: '5%' },
    { key: 'type', title: 'Type', width: '3%' },
    { key: 'firmwares', title: 'Firmwares', width: '5%' },
    { key: 'details', title: 'Details', width: '10%' },
    { key: 'actions', title: '', width: '1%' },
  ];

  async ngOnInit(): Promise<void> {
    if (this.thingname) {
      this.thingDeploymentHistory$ = this.thingService
        .getThingDeploymentHistory(this.thingname)
        .pipe(
          catchError((error) => {
            this.notificationService.showError(
              'Could not load deployment history',
              error,
            );
            return of([]);
          }),
        );
    }
  }

  /**
   * Allows IDEs to correctly infer the object type to provide completion and checks in HTML template
   */
  typedHistoryItem(item: ThingDeployment): ThingDeployment {
    return item;
  }
}
