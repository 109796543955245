import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Job, JobExecutionStatus } from '@aws-sdk/client-iot';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
  CreateDeploymentOptions,
  CreateDeploymentRequest,
} from '../../../../models/backend/deployment/create-deployment-request';
import { CreateDeploymentResponse } from '../../../../models/backend/deployment/create-deployment-response';
import { FirmwareDistributionResponse } from '../../../../models/backend/deployment/firmware-distribution';
import {
  GetExecutionsQueryParams,
  GetExecutionsResponseWithStringDates,
} from '../../../../models/backend/deployment/get-executions';
import { Utils } from '../../../../shared/utils';

@Injectable({
  providedIn: 'root',
})
export class DeploymentsService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly http: HttpClient) {}

  /**
   * Creates a deployment job by calling backend API.
   *
   * @returns the newly created job id
   */
  startDeploymentJob(
    metaversionId: string,
    createDeploymentOptions: CreateDeploymentOptions,
  ): Observable<CreateDeploymentResponse> {
    const requestBody: CreateDeploymentRequest = {
      metaversionId,
      ...createDeploymentOptions,
    };

    return this.http.post<CreateDeploymentResponse>(
      `${this.backendUrl}/deployments`,
      requestBody,
    );
  }

  /**
   * Calls backend to fetch the firmware distribution for any given Job id
   * @returns the firmware distribution
   */
  getFirmwareDistribution(
    jobId?: string,
  ): Observable<FirmwareDistributionResponse> {
    return this.http.get<FirmwareDistributionResponse>(
      `${this.backendUrl}/deployments/${jobId}/distribution`,
    );
  }

  /**
   * Calls backend to fetch a Job by its id
   */
  getDeployment(jobId: string): Observable<Job> {
    return this.http.get<Job>(`${this.backendUrl}/deployments/${jobId}`);
  }

  /**
   * Calls backend to fetch Job Executions by their Job id
   */
  getExecutions(
    jobId: string,
    status?: JobExecutionStatus | undefined,
    nextToken?: string | undefined,
  ): Observable<GetExecutionsResponseWithStringDates> {
    return this.http.get<GetExecutionsResponseWithStringDates>(
      `${this.backendUrl}/deployments/${jobId}/executions`,
      {
        params: Utils.deleteNullishValues({
          status,
          nextToken,
        }) as GetExecutionsQueryParams,
      },
    );
  }

  /**
   * Calls backend to cancel a Job by its id, as well as the related jobs
   */
  cancelDeployment(jobId: string): Observable<void> {
    return this.http.post<void>(
      `${this.backendUrl}/deployments/${jobId}/cancel`,
      {},
    );
  }
}
