<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Page Heading -->
    <h1 class="text-gray-800 font-weight-bold">User Manifest</h1>
    <!-- Page Body -->
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <form action="" class="form-row align-items-center">
                <div class="form-switch">
                    <input type="checkbox" id="activated" name="activated" [formControl]="includeUserLoginControl"
                           class="form-check-input"/>
                    <label class="form-check-label" for="activated">
                        Include user logins
                    </label>
                </div>
            </form>
        </div>
        <div class="card-body" *ngIf="(auditLogs$|async) as auditLogs else loadingTable">
            <app-ngx-table-with-query-params-persistence
                    [columns]="columns" [data]="auditLogs" [configuration]="configuration"
                    [filtersFormGroup]="formGroupLocalFilters" [filtersTemplate]="filtersTemplate"
                    [localFilter]="false" [pagination]="pagination"
                    (event)="handleTableEvent($event)"
            >
                <ng-template let-untypedAuditLog>
                    <ng-container *ngIf="typeAuditLog(untypedAuditLog) as auditLog">
                        <td>{{auditLog.date | displayFormatDate}}</td>
                        <td>{{auditLog.userId | displayCreatorName}}</td>
                        <td>{{auditLog.type}}</td>
                        <td>{{auditLog.action}}</td>
                        <td>{{getLegibleResourceId(auditLog) || auditLog.resourceId || '-'}}</td>
                        <td>
                          <app-additional-data-display [additionalData]="auditLog.additionalData ?? {}" />
                        </td>
                        <td class="btn-group">
                            <a *ngIf="buildResourcePathUrl(auditLog) as routerLink" class="btn btn-eye" [routerLink]="routerLink.href" [queryParams]="routerLink.queryParams">
                                <i class="fas fa-eye" aria-hidden="true"></i>
                            </a>
                        </td>
                    </ng-container>
                </ng-template>
            </app-ngx-table-with-query-params-persistence>
        </div>
    </div>
</div>

<ng-template #filtersTemplate>
    <ng-container [formGroup]="formGroupLocalFilters">
        <th scope="col" class="pl-0 pr-3 pt-0">
            <span class="d-flex flex-row">
                <input class="form-control" type="text" formControlName="dateMin" placeholder="After"/>
                <input class="form-control" type="text" formControlName="dateMax" placeholder="Before"/>
            </span>
        </th>
        <th scope="col" class="pl-0 pr-3 pt-0">
            <input class="form-control" type="text" formControlName="userId" placeholder="Id, name, email ..."/>
        </th>
        <th scope="col" class="pl-0 pr-3 pt-0">
            <select class="form-select" id="type" formControlName="type" name="type">
                <option selected [ngValue]="null">All</option>
                @for (_type of auditLogTypes$|async; track _type) {
                    <option [ngValue]="_type">{{_type}}</option>
                }
            </select>
        </th>
        <th scope="col" class="pl-0 pr-3 pt-0">
            <select class="form-select" id="action" formControlName="action" name="action">
                <option selected [ngValue]="null">All</option>
                @for (_action of auditLogActions$|async; track _action) {
                    <option [ngValue]="_action">{{_action}}</option>
                }
            </select>
        </th>
        <th scope="col" class="pl-0 pr-3 pt-0">
            <input class="form-control" type="text" formControlName="resourceId" placeholder="Resource id"/>
        </th>
        <th scope="col" class="pl-0 pr-3 pt-0">
            <input class="form-control" type="text" formControlName="additionalData" placeholder="thing name, resource id, filename ..."/>
        </th>
        <th scope="col">
            <button class="btn btn-primary" (click)="formGroupLocalFilters.reset()" ngbTooltip="Reset filters">
                <i class="fas fa-undo-alt" aria-hidden="true"></i>
            </button>
        </th>
    </ng-container>
</ng-template>

<ng-template #loadingTable>
    <div class="card-body">
        <p>Loading ... <app-spinner-small/></p>
    </div>
</ng-template>
