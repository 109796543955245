import { NgClass, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RolloutConfiguration } from '../../models/backend/metaversion/progressive-rollout-configuration';
import { RolloutUtils } from '../../shared/utils/rollout.utils';

@Component({
  selector: 'app-progressive-rollout-dialog',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, FormsModule, NgClass],
  templateUrl: './progressive-rollout-dialog.component.html',
})
export class ProgressiveRolloutDialogComponent implements OnInit {
  protected readonly modal = inject(NgbActiveModal);

  MINIMUM_DEVICES_PER_DAY = 1440; // 1 per minute = 1 * 60 * 24
  DEFAULT_ABORT_AMOUNT = 100;
  MAXIMUM_TARGETS_PER_JOB = 100;

  totalCount!: number;
  isTargeted: boolean = false;

  abortConfigToggle = false;
  disableAbortConfig = false;

  rolloutForm: FormGroup = new FormGroup({
    maximumPerDay: new FormControl<number>(this.MINIMUM_DEVICES_PER_DAY, [
      Validators.required,
      Validators.min(this.MINIMUM_DEVICES_PER_DAY),
      // 1000 per minute max = 1000 * 60 * 24 = 1440000 per day max
      Validators.max(1_440_000),
      Validators.pattern('^[0-9]*$'),
    ]),
  });

  abortForm: FormGroup = new FormGroup({
    thresholdPercentage: new FormControl<number>(5, [
      Validators.required,
      Validators.min(1),
      Validators.max(100),
      Validators.pattern('^[0-9]*$'),
    ]),
    minimumDevicesDeployed: new FormControl<number>(this.DEFAULT_ABORT_AMOUNT, [
      Validators.required,
      Validators.min(1),
      Validators.pattern('^[0-9]*$'),
    ]),
  });

  ngOnInit(): void {
    this.abortForm.controls['minimumDevicesDeployed'].addValidators(
      Validators.max(this.totalCount),
    );
    this.abortForm.updateValueAndValidity();

    if (this.totalCount < this.DEFAULT_ABORT_AMOUNT) {
      // arbitrarily sets to 10% of the total number of devices if the default value is higher
      this.abortForm.patchValue({
        minimumDevicesDeployed: Math.ceil(this.totalCount / 10),
      });
    }

    if (this.isTargeted && this.totalCount > this.MAXIMUM_TARGETS_PER_JOB) {
      this.disableAbortConfig = true;
    }
  }

  /**
   * switches modal width between large and default
   */
  toggledAbortConfig(): void {
    if (this.abortConfigToggle) {
      this.modal.update({
        modalDialogClass: 'modal-xl',
      });
    } else {
      this.modal.update({
        modalDialogClass: '',
      });
    }
  }

  confirm(): void {
    if (this.rolloutForm.invalid) {
      return;
    }

    const maximumPerMinute = this.computeMaximumPerMinute();

    const config: RolloutConfiguration = {
      progressiveRolloutConfiguration: {
        maximumPerMinute: maximumPerMinute,
      },
    };

    if (this.abortConfigToggle) {
      if (this.abortForm.invalid) {
        return;
      }

      const abortFormValue = this.abortForm.value;
      config.abortConfiguration = {
        failureType: 'FAILED',
        action: 'CANCEL',
        thresholdPercentage: abortFormValue.thresholdPercentage,
        minNumberOfExecutedThings: abortFormValue.minimumDevicesDeployed,
      };
    }

    this.modal.close(config);
  }

  computeMaximumPerMinute(): number {
    const formValues = this.rolloutForm.value;

    const maximumPerDay =
      formValues.maximumPerDay < this.MINIMUM_DEVICES_PER_DAY
        ? this.MINIMUM_DEVICES_PER_DAY
        : formValues.maximumPerDay;

    return Math.ceil(maximumPerDay / 60 / 24);
  }

  cancel(): void {
    this.modal.close();
  }

  computeEstimatedTime(): string {
    const total = this.totalCount ?? 1;
    const maximumPerMinute = this.computeMaximumPerMinute();

    return RolloutUtils.computeEstimatedTime(total, maximumPerMinute);
  }

  setToMinimum(): void {
    this.rolloutForm.patchValue({
      maximumPerDay: this.MINIMUM_DEVICES_PER_DAY,
    });
  }

  setToTotalCount(): void {
    const totalCount = this.totalCount ?? this.MINIMUM_DEVICES_PER_DAY;
    this.rolloutForm.patchValue({
      maximumPerDay:
        totalCount > this.MINIMUM_DEVICES_PER_DAY
          ? totalCount
          : this.MINIMUM_DEVICES_PER_DAY,
    });
  }
}
