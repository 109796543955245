import { KeyValuePipe, NgForOf } from '@angular/common';
import { Component, input } from '@angular/core';
import { AuditAdditionalData } from '@common/audit-log/models/AuditLog';
import { VersionFilterCount } from '@common/models/version-filter-count';
import { RolloutConfiguration } from '../../models/backend/metaversion/progressive-rollout-configuration';
import { ReplacePipe } from '../../shared/pipes/replace.pipe';

@Component({
  selector: 'app-additional-data-display',
  standalone: true,
  imports: [KeyValuePipe, ReplacePipe, NgForOf],
  templateUrl: './additional-data-display.component.html',
  styleUrl: './additional-data-display.component.scss',
})
export class AdditionalDataDisplayComponent {
  additionalData = input<AuditAdditionalData>();

  protected readonly Array = Array;

  typedVersionFilterCount(untyped: unknown): VersionFilterCount[] {
    return untyped as VersionFilterCount[];
  }

  typedRolloutConfiguration(untyped: unknown): RolloutConfiguration {
    return untyped as RolloutConfiguration;
  }

  typedArray(untyped: unknown): unknown[] {
    return untyped as unknown[];
  }
}
