import { NativeAttributeValue } from '@aws-sdk/util-dynamodb';

import { VersionFilterCount } from '@common/models/version-filter-count';
import * as moment from 'moment';
import { JobType } from '@common/models/job-type';

export class MetaVersionJob {
  metaversionId: string;
  jobId: string;
  date: Date;
  dateStr?: string;
  jobType: JobType;
  groupId?: string;
  targetedVersions?: VersionFilterCount[];

  constructor(
    metaversionId: string,
    jobId: string,
    date: Date,
    jobType: JobType,
    groupId?: string,
    targetedVersions?: VersionFilterCount[],
  ) {
    this.metaversionId = metaversionId;
    this.jobId = jobId;
    this.date = date;
    this.dateStr = moment(date).format('YYYY-MM-DD HH:mm:ss');
    this.jobType = jobType;
    this.groupId = groupId;
    this.targetedVersions = targetedVersions;
  }

  static mapDynamoOutputToMetaversionJobs(
    dynamoOutput: Record<string, NativeAttributeValue>[],
  ): MetaVersionJob[] {
    return dynamoOutput.map(
      (item: Record<string, NativeAttributeValue>) =>
        new MetaVersionJob(
          item.metaversionId,
          item.jobId,
          new Date(item.date),
          item.jobType,
          undefined,
          item.targetedVersions,
        ),
    );
  }
}
