<div class="vstack gap-0 text-end lh-sm" *ngIf="statistics">
  <span *ngIf="statistics.queuedAt as queuedAt"
    >Queued at <strong>{{ queuedAt | displayFormatDate }}</strong></span
  >
  <span *ngIf="statistics.setShadowAt as setShadowAt"
    >Set in Shadow at
    <strong>{{ setShadowAt | displayFormatDate }}</strong></span
  >
  <span *ngIf="statistics.doneAt as doneAt"
    >Finished at
    <span class="h5"
      ><strong class="badge badge-success">{{
        doneAt | displayFormatDate
      }}</strong></span
    ></span
  >
  <span *ngIf="statistics.presignUrlCount as count" class="align-items-center">
    Presign URL requested
    <span class="h6"
      ><span
        [ngbPopover]="presignedAtTooltip"
        class="badge btn-primary dotted"
        triggers="mouseenter:mouseleave"
        >{{ count }} times</span
      ></span
    >
  </span>
  <span *ngIf="statistics.shadowToDoneDuration as shadowToDoneDuration">
    <i class="fa fa-stopwatch"></i> took
    <strong
      [ngbPopover]="durationTooltip"
      class="dotted"
      triggers="mouseenter:mouseleave"
      >{{ shadowToDoneDuration | HumanizeDuration }}</strong
    >
  </span>

  <ng-template #presignedAtTooltip>
    <div
      *ngFor="let _versionFilterCount of statistics.askedPresignUrlAt"
      class="m-0 p-0 vstack gap-1"
    >
      <span>{{ _versionFilterCount | displayFormatDate }}</span>
    </div>
  </ng-template>
  <ng-template #durationTooltip>
    <div class="vstack gap-1">
      <span *ngIf="statistics.shadowToDoneDuration as shadowToDoneDuration"
        >From inserted shadow :
        <strong>{{ shadowToDoneDuration | HumanizeDuration }}</strong></span
      >
      <span *ngIf="statistics.presignToDoneDuration as doneDuration"
        >From first presign url :
        <strong>{{ doneDuration | HumanizeDuration }}</strong></span
      >
    </div>
  </ng-template>
</div>
