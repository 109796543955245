import { Pipe, PipeTransform } from '@angular/core';
import { FirmwareType } from '../../models/backend/firmware/firmware-type';
import { ThingType } from '../../models/thingtype';
import { FirmwareUtils } from '../utils/firmware.utils';

@Pipe({
  name: 'formatFirmwareId',
  standalone: true,
})
export class FormatFirmwareIdPipe implements PipeTransform {
  transform(firmwareId: string | undefined):
    | {
        thingType: ThingType;
        type: FirmwareType;
        version: string;
      }
    | undefined {
    if (!firmwareId) {
      return;
    }

    return FirmwareUtils.splitFirmwareId(firmwareId);
  }
}
