import { NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JobType } from '@common/models/job-type';
import { VersionFilterCount } from '@common/models/version-filter-count';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ReplacePipe } from '../pipes/replace.pipe';

@Component({
  selector: 'app-job-type-badge',
  templateUrl: './job-type-badge.component.html',
  styleUrls: ['./job-type-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgbTooltip, NgIf, ReplacePipe, NgForOf],
})
export class JobTypeBadgeComponent {
  @Input() jobType?: JobType;
  @Input() versionFilterCount?: VersionFilterCount[];
  @Input() block?: boolean = false;
}
