import { NativeAttributeValue } from '@aws-sdk/util-dynamodb';
import { JobType } from '@common/models/job-type';

import { VersionFilterCount } from '@common/models/version-filter-count';
import * as moment from 'moment';
import { RolloutConfiguration } from './backend/metaversion/progressive-rollout-configuration';

export class MetaVersionJob {
  metaversionId: string;
  jobId: string;
  date: Date;
  dateStr?: string;
  jobType: JobType;
  groupId?: string;
  targetedVersions?: VersionFilterCount[];
  progressiveRollout?: RolloutConfiguration;
  relatedJobs?: string[];

  constructor(
    metaversionId: string,
    jobId: string,
    date: Date,
    jobType: JobType,
    groupId?: string,
    targetedVersions?: VersionFilterCount[],
    progressiveRollout?: RolloutConfiguration,
    relatedJobs?: string[],
  ) {
    this.metaversionId = metaversionId;
    this.jobId = jobId;
    this.date = date;
    this.dateStr = moment(date).format('YYYY-MM-DD HH:mm:ss');
    this.jobType = jobType;
    this.groupId = groupId;
    this.targetedVersions = targetedVersions;
    this.progressiveRollout = progressiveRollout;
    this.relatedJobs = relatedJobs;
  }

  static mapDynamoOutputToMetaversionJobs(
    dynamoOutput: Record<string, NativeAttributeValue>[],
  ): MetaVersionJob[] {
    return dynamoOutput.map((item: Record<string, NativeAttributeValue>) =>
      this.mapDynamoOutputToMetaversionJob(item),
    );
  }

  static mapDynamoOutputToMetaversionJob(
    item: Record<string, NativeAttributeValue>,
  ): MetaVersionJob {
    return new MetaVersionJob(
      item.metaversionId,
      item.jobId,
      new Date(item.date),
      item.jobType,
      item.groupId,
      item.targetedVersions,
      item.progressiveRollout,
      item.relatedJobs,
    );
  }
}
