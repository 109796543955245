import { NgForOf, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from '../../generic/spinner/spinner.component';
import { GetMetaversionFilteredThingsResult } from '../../models/backend/metaversion/get-things-request-body';

@Component({
  selector: 'app-deploy-filtered-things-dialog',
  standalone: true,
  imports: [NgIf, NgForOf, SpinnerComponent],
  templateUrl: './deploy-filtered-things-dialog.component.html',
  styleUrl: './deploy-filtered-things-dialog.component.scss',
})
export class DeployFilteredThingsDialogComponent {
  protected readonly modal = inject(NgbActiveModal);

  filteredThingsResult: GetMetaversionFilteredThingsResult | undefined;

  confirm(): void {
    this.modal.close(true);
  }

  cancel(): void {
    this.modal.close(false);
  }
}
