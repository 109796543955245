import { Directive } from '@angular/core';
import { FeatureGroupEnum } from '../feature-group.enum';
import { AbstractGroupBasedDirective } from './abstract-group-based.directive';

@Directive({
  selector: '[canCancelDeployment]',
  standalone: true,
})
export class CancelDeploymentDirective extends AbstractGroupBasedDirective {
  protected override getSpecificGroup(): FeatureGroupEnum {
    return FeatureGroupEnum.CANCEL_DEPLOYMENT;
  }
}
