import { JobSummary } from '@aws-sdk/client-iot';
import { JobType } from '@common/models/job-type';
import { VersionFilterCount } from '@common/models/version-filter-count';
import * as moment from 'moment';
import { RolloutConfiguration } from './backend/metaversion/progressive-rollout-configuration';
import { ThingType } from './thingtype';

export class Job {
  public jobId: string;
  public status: string;
  public targetSelection: string;
  public thingGroupId: string;
  public createdAt?: Date;
  public createdAtStr: string;
  public lastUpdatedAt?: Date;
  public completedAt?: Date;
  public jobType: string;
  public thingType: ThingType;
  public metaversionId?: string;
  public singleOrMulti?: JobType;
  public createdBy?: string;
  public versionFilterCounts?: VersionFilterCount[];
  public progressiveRollout?: RolloutConfiguration;

  constructor(
    jobId: string,
    status: string,
    targetSelection: string,
    thingGroupId: string,
    createdAt?: Date,
    lastUpdatedAt?: Date,
    completedAt?: Date,
    createdBy?: string,
  ) {
    this.jobId = jobId;
    this.status = status;
    this.targetSelection = targetSelection;
    this.thingGroupId = thingGroupId;
    this.createdAt = createdAt;
    this.createdAtStr = moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
    this.lastUpdatedAt = lastUpdatedAt;
    this.completedAt = completedAt;
    this.createdBy = createdBy;

    const parts = this.jobId.split('_');
    this.jobType = parts[0] as JobType;
    this.thingType = parts[1] as ThingType;
  }

  public static fromItemList(jobList: JobSummary[]): Job[] {
    return jobList.map(
      (job: JobSummary) =>
        new Job(
          job.jobId ?? '',
          job.status ?? '',
          job.targetSelection ?? '',
          job.thingGroupId ?? '',
          job.createdAt,
          job.lastUpdatedAt,
          job.completedAt,
        ),
    );
  }

  public addMetaversionId(metaversionId: string): this {
    this.metaversionId = metaversionId;
    return this;
  }

  public addCreatedBy(createdBy?: string): this {
    this.createdBy = createdBy;
    return this;
  }

  public addSingleOrMulti(type: JobType): this {
    this.singleOrMulti = type;
    return this;
  }

  public addVersionFilterCounter(
    versionFilterCounts?: VersionFilterCount[],
  ): this {
    this.versionFilterCounts = versionFilterCounts;
    return this;
  }

  public addProgressiveRolloutConfig(
    progressiveRollout?: RolloutConfiguration,
  ): this {
    this.progressiveRollout = progressiveRollout;
    return this;
  }
}
