import { JobType } from "../../models/job-type";
import { VersionFlag } from "../../models/version-flag.enum";

import { VersionFilterCount } from "../../models/version-filter-count";
import { Paginator } from "../../models/paginator";

export type MappedAuditLog = {
  [resourcedId: string]: AuditLog;
}

export interface PaginatedSearchAuditLog extends Paginator {
  data: SearchAuditLog[],
}

export class SearchAuditLog {
  type: AuditType;
  action: AuditAction;
  userId: string;
  firstname: string;
  lastname: string;
  resourceId: string;
  additionalData?: AuditAdditionalData;
  date: string;

  constructor(obj: SearchAuditLog) {
    this.type = obj.type;
    this.action = obj.action;
    this.userId = obj.userId;
    this.firstname = obj.firstname;
    this.lastname = obj.lastname;
    this.resourceId = obj.resourceId;
    this.additionalData = typeof obj.additionalData === 'string' ? JSON.parse(obj.additionalData) : undefined;
    this.date = obj.date;
  }
}

export interface FilterQueryParameters {
  type?: string|string[];
  action?: string|string[];
  userId?: string;
  resourceId?: string;
  dateStart?: string;
  dateEnd?: string;
  additionalData?: string;
  sort?: string;
  order?: 'asc'|'desc'|'';
}

export interface AuditLog {
  type: AuditType;
  action: AuditAction;
  userId: string;
  resourceId: string;
  additionalData?: AuditAdditionalData;
  date: string;
}

export enum AuditType {
  USER = 'USER',
  FIRMWARE = 'FIRMWARE',
  FACTORY_FIRMWARE = 'FACTORY_FIRMWARE',
  METAVERSION = 'METAVERSION',
  DEPLOYMENT = 'DEPLOYMENT',
  THING = 'THING',
  THING_GROUP = 'THING_GROUP',
  PRODUCT_LOG = 'PRODUCT_LOG',
}

export enum AuditAction {
  LOGIN = 'LOGIN',
  SET_ROLE = 'SET_ROLE',
  UPLOAD = 'UPLOAD',
  PATCH = 'PATCH',
  DEPRECATE = 'DEPRECATE',
  RESTORE = 'RESTORE',
  ADD_FILE = 'ADD_FILE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  CREATE = 'CREATE',
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  STOP = 'STOP',
  RESUME = 'RESUME',
  START = 'START',
  ACTIVATE_LOG = 'ACTIVATE_LOG',
  DEACTIVATE_LOG = 'DEACTIVATE_LOG',
  ADD_THING = 'ADD_THING',
  REMOVE_THING = 'REMOVE_THING',
  CLEAR = 'CLEAR',
  NICKNAMED = 'NICKNAMED',
}

export const AllowedAuditTypeActionPairs = {
  [AuditType.USER]:             [AuditAction.LOGIN, AuditAction.SET_ROLE],
  [AuditType.FIRMWARE]:         [AuditAction.UPLOAD, AuditAction.DEPRECATE, AuditAction.RESTORE, AuditAction.ADD_FILE, AuditAction.UPDATE, AuditAction.PATCH,],
  [AuditType.FACTORY_FIRMWARE]: [AuditAction.UPLOAD, AuditAction.DELETE,],
  [AuditType.METAVERSION]:      [AuditAction.CREATE, AuditAction.DEPRECATE, AuditAction.ENABLE, AuditAction.DISABLE,],
  [AuditType.DEPLOYMENT]:       [AuditAction.STOP, AuditAction.RESUME, AuditAction.START,],
  [AuditType.THING]:            [AuditAction.ACTIVATE_LOG, AuditAction.DEACTIVATE_LOG, AuditAction.DELETE, AuditAction.NICKNAMED],
  [AuditType.THING_GROUP]:      [AuditAction.CREATE, AuditAction.DELETE, AuditAction.ADD_THING, AuditAction.REMOVE_THING,],
  [AuditType.PRODUCT_LOG]:      [AuditAction.CLEAR, AuditAction.DELETE,],
}

export type AuditAdditionalData =
  | AuditActionAdditionalData
  | UserAdditionalData;

export type UserAdditionalData = {
  seb_id: string;
  sub: string;
  firstname: string;
  lastname: string;
  email: string;
  account_created_at: string;
};

export type AuditActionAdditionalData =
  & MiscActionAdditionalData
  & FirmwareAdditionalData;

export type FirmwareAdditionalData = {
  filename?: string;
  filenames?: string[];
  metaversion_id?: string;
  version?: string;
  ui_firmware_id?: string;
  wifi_firmware_id?: string;
  versionFlag?: VersionFlag | string;
};

export type MiscActionAdditionalData = {
  job_id?: string;
  thing_type?: string;
  thingname?: string;
  macAddress?: string;
  target?: JobType;
  nickname?: string | null;
  thing_group_id?: string;
  thing_group_name?: string;
  role_name?: string;
  role_groups?: string[];
  version_targeted?: VersionFilterCount[],
};
