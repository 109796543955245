import { Component, Input } from '@angular/core';
import { VersionFlag } from '@common/models/version-flag.enum';
import { Shadow, ShadowStateFirmwareFile } from '../../models/shadow';
import { Firmware } from '../../models/firmware';
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-firmware-version',
  standalone: true,
  imports: [UpperCasePipe],
  templateUrl: './firmware-version.component.html',
})
export class FirmwareVersionComponent {
  protected readonly VersionFlag = VersionFlag;

  @Input({ required: true }) files!: ShadowStateFirmwareFile[];

  @Input({ required: true }) firmwares!: Record<string, Firmware>;

  @Input({ required: true }) thingTypeName!: string;

  getFirmwareIdFromFile(
    file: ShadowStateFirmwareFile,
    thingType: string,
  ): string {
    return Shadow.getFirmwareIdFromFile(file, thingType);
  }
}
