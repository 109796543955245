import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditType } from '@common/audit-log/models/AuditLog';
import { VersionFlag } from '@common/models/version-flag.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceException } from '@smithy/smithy-client';
import {
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { MetaversionService } from '../api/backend/services/metaversion/metaversion.service';
import { DatabaseService } from '../api/database.service';
import { DeployService } from '../api/deploy.service';
import {
  DeployCount,
  DeployCountDetail,
  RegistryService,
} from '../api/registry.service';
import { StoreService } from '../lib/store.service';
import { CreateDeploymentOptions } from '../models/backend/deployment/create-deployment-request';
import { CreateDeploymentResponse } from '../models/backend/deployment/create-deployment-response';
import { VersionFilter } from '../models/backend/metaversion/deployment-version-filter';
import { RolloutConfiguration } from '../models/backend/metaversion/progressive-rollout-configuration';
import { DeployAttribute } from '../models/deploy-attributes';
import {
  CriteriaKey,
  CriteriaType,
  Firmware,
  ParsedCriteriaKey,
} from '../models/firmware';
import { MetaVersionJob } from '../models/meta-version-job.model';
import { RolloutConfirmation } from '../models/metaversion/confirm-deployment';
import { MetaVersion } from '../models/metaversion/meta-version';
import { ThingGroup } from '../models/thingtype';
import { NotificationService } from '../shared/notification.service';
import { FeatureGroupEnum } from '../shared/user-rights-management/feature-group.enum';
import { ConfirmMultiDeploymentDialogComponent } from './confirm-multi-deployment-dialog/confirm-multi-deployment-dialog.component';
import { DeployFilteredThingsDialogComponent } from './deploy-filtered-things-dialog/deploy-filtered-things-dialog.component';
import { ProgressiveRolloutDialogComponent } from './progressive-deployment-dialog/progressive-rollout-dialog.component';
import { VersionFilterDialogComponent } from './version-filter/version-filter-dialog.component';

@Component({
  selector: 'app-metaversion',
  templateUrl: './metaversion.component.html',
  styleUrls: ['./metaversion.component.css'],
})
export class MetaversionComponent implements OnInit, OnDestroy {
  protected readonly VersionFlag = VersionFlag;

  @ViewChild('deployDialog') deployDialog?: ElementRef;
  @ViewChild('dialogNoThingGroup') dialogNoThingGroup?: ElementRef;

  isLoading = false;
  deployAttribute = 'thingType' as DeployAttribute;
  metaVersion?: MetaVersion;
  metaVersionId?: string;
  deployCount?: DeployCount;
  metaversionFirmwares: Firmware[] = [];

  massDeployRole = false;

  shouldDisplayRange = false;
  shouldDisplayCmmf = false;
  shouldDisplayIndice = false;
  shouldDisplayBrandArea = false;

  jobs$?: Observable<MetaVersionJob[]>;
  multiOnly$ = new BehaviorSubject<boolean>(false);
  formGroupJobsFilters = new UntypedFormGroup({
    multiOnly: new UntypedFormControl(false),
  });

  resetTableFilters = new EventEmitter<void>();

  preparing = false;
  deploying = false;
  destroy$ = new Subject<void>();
  loadThingGroups$ = new BehaviorSubject<boolean>(false);
  thingGroups$?: Observable<ThingGroup[]>;
  thingGroupsExist$?: Observable<boolean>;

  readonly AuditType = AuditType;

  constructor(
    private readonly router: Router,
    private readonly notif: NotificationService,
    private readonly store: StoreService,
    private readonly dataBaseService: DatabaseService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly registryService: RegistryService,
    private readonly deployService: DeployService,
    private readonly metaversionService: MetaversionService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.massDeployRole = this.store.userHasGroup(
      FeatureGroupEnum.DEPLOY_WITH_CRITERIA,
    );

    this.activatedRoute.paramMap.subscribe(async (params) => {
      if (params.get('metaversionId')) {
        this.isLoading = true;
        this.metaVersionId = params.get('metaversionId') as string;

        this.jobs$ = combineLatest([
          this.dataBaseService.getJobsForMetaVersion(this.metaVersionId),
          this.multiOnly$,
        ]).pipe(
          map(([jobs, multiOnly]) =>
            jobs.filter(
              (job) =>
                !multiOnly ||
                job.jobType === 'MULTI' ||
                job.jobType === 'MULTI_TARGETED',
            ),
          ),
        );

        this.metaVersion = (
          await this.dataBaseService.listMetaVersions(1)
        ).find((_) => _.id === this.metaVersionId);

        if (this.metaVersion !== undefined && this.metaVersion !== null) {
          this.metaversionFirmwares = this.metaVersion.getFirmwares();
        }

        const criteriaType =
          this?.metaVersion?.uiFirmware?.criteriaType || CriteriaType.THINGTYPE;
        this.shouldDisplayRange = [
          CriteriaType.RANGE,
          CriteriaType.RANGE_INDICE,
        ].includes(criteriaType);
        this.shouldDisplayCmmf = [
          CriteriaType.CMMF,
          CriteriaType.CMMF_INDICE,
        ].includes(criteriaType);
        this.shouldDisplayIndice = [
          CriteriaType.RANGE_INDICE,
          CriteriaType.CMMF_INDICE,
        ].includes(criteriaType);
        this.shouldDisplayBrandArea = Object.keys(
          this.metaVersion?.uiFirmware?.s3Key || {},
        ).some(
          (s3key) =>
            ParsedCriteriaKey.fromCriteriaKey(s3key as CriteriaKey)
              .brandArea !== 'NA',
        );

        if (this.massDeployRole) {
          this.initThingGroupObservables();
        }

        this.isLoading = false;
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.jobsMultiOnly === 'true') {
        this.multiOnly$.next(true);
        this.formGroupJobsFilters.patchValue({ multiOnly: true });
      }
    });
  }

  public prepareDeployment(): void {
    if (!this.massDeployRole) {
      return;
    }
    this.preparing = true;
    this.loadThingGroups$.next(true);
  }

  public startDeployment(): void {
    if (!this.massDeployRole || !this.metaVersion?.id) {
      return;
    }
    this.deploying = true;

    this.thingGroups$
      ?.pipe(
        switchMap((thingGroups) =>
          this.registryService
            .countDeployGroups(thingGroups, this.metaVersion?.id ?? '')
            .pipe(
              tap((countDetails) => this.initThingCount(countDetails)),
              mapTo(thingGroups),
            ),
        ),
      )
      .subscribe(async (thingGroups) => {
        // Ask for user confirmation to approve the deploy
        const confirmMultiDeployment =
          await this.askForMultiDeploymentConfirmation();

        if (
          confirmMultiDeployment === RolloutConfirmation.CANCEL ||
          !this.metaVersion
        ) {
          this.deploying = false;
          return;
        }

        let rolloutConfiguration: RolloutConfiguration | undefined;
        if (confirmMultiDeployment === RolloutConfirmation.PROGRESSIVE) {
          rolloutConfiguration =
            await this.askProgressiveDeploymentConfirmation(
              this.deployCount!.totalCount,
            );
          if (!rolloutConfiguration) {
            this.deploying = false;
            return;
          }
        }

        const thingGroupArns = thingGroups.map(
          (_thingGroup) => _thingGroup.thingGroupArn,
        );

        this.doDeploy({
          thingGroupArns: thingGroupArns,
          rolloutConfiguration: rolloutConfiguration,
        }).subscribe({
          next: async (res: CreateDeploymentResponse | undefined) => {
            this.deploying = false;
            if (!res) return;

            await this.router.navigateByUrl('/deployments');
          },
          error: (err) => {
            this.notif.showError(err?.message ?? 'An error occurred', err);
            this.deploying = false;
          },
        });
      });
  }

  public async startDeploymentForThingsWithVersion(): Promise<void> {
    if (!this.metaVersion) {
      return;
    }

    const versionFilters = await this.askForVersionFilters();
    if (!versionFilters?.length) {
      return;
    }

    // Ask validation of the filtered estimations ==============================
    // modal is opened before the request, in order to have a loader in the meantime
    const deployFilteredThingsDialogInstance = this.modalService.open(
      DeployFilteredThingsDialogComponent,
      {
        backdrop: 'static',
        centered: true,
      },
    );

    const filterCounts = await lastValueFrom(
      this.metaversionService.findThingsWithVersion(
        this.metaVersion,
        versionFilters,
      ),
    );

    deployFilteredThingsDialogInstance.componentInstance.filteredThingsResult =
      filterCounts;

    const confirmDeploy =
      (await deployFilteredThingsDialogInstance.result) as RolloutConfirmation;
    if (confirmDeploy === RolloutConfirmation.CANCEL) {
      return;
    }

    let rolloutConfiguration: RolloutConfiguration | undefined;
    if (confirmDeploy === RolloutConfirmation.PROGRESSIVE) {
      rolloutConfiguration = await this.askProgressiveDeploymentConfirmation(
        filterCounts.totalFiltered,
        true,
      );
      if (!rolloutConfiguration) {
        this.deploying = false;
        return;
      }
    }

    // Start the "filtered" deployment =========================================
    this.doDeploy({
      thingNames: filterCounts.thingsArn,
      filterCounts: filterCounts.filterCounts,
      rolloutConfiguration: rolloutConfiguration,
    }).subscribe({
      next: async (res: CreateDeploymentResponse | undefined) => {
        this.deploying = false;
        if (!res) return;

        await this.router.navigateByUrl('/deployments');
      },
      error: (err) => {
        this.notif.showError(err?.message ?? 'An error occurred', err);
        this.deploying = false;
      },
    });
  }

  async askForVersionFilters(): Promise<VersionFilter[]> {
    const filtersModal = this.modalService.open(VersionFilterDialogComponent, {
      backdrop: 'static',
      centered: true,
    });

    return await filtersModal.result;
  }

  async askForMultiDeploymentConfirmation(): Promise<RolloutConfirmation> {
    const filtersModal = this.modalService.open(
      ConfirmMultiDeploymentDialogComponent,
      {
        backdrop: 'static',
        centered: true,
      },
    );

    filtersModal.componentInstance.deployCount = this.deployCount;

    return await filtersModal.result;
  }

  async askProgressiveDeploymentConfirmation(
    totalCount: number,
    isTargeted = false,
  ): Promise<RolloutConfiguration> {
    const progressiveDeploymentDialog = this.modalService.open(
      ProgressiveRolloutDialogComponent,
      {
        backdrop: 'static',
        centered: true,
      },
    );

    progressiveDeploymentDialog.componentInstance.totalCount = totalCount;
    progressiveDeploymentDialog.componentInstance.isTargeted = isTargeted;

    return await progressiveDeploymentDialog.result;
  }

  /**
   * Shows the user an error modal to warn them the Thing Groups don't exist
   */
  showBlockingModalNoThingGroupExist(): void {
    this.modalService.open(this.dialogNoThingGroup, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
    });
  }

  filterJobs(): void {
    this.multiOnly$.next(this.formGroupJobsFilters.value.multiOnly ?? false);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        jobsMultiOnly: this.formGroupJobsFilters.value.multiOnly ? true : null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  resetFilters(): void {
    this.resetTableFilters.emit();

    if (this.formGroupJobsFilters.value.multiOnly) {
      this.formGroupJobsFilters.reset();
      this.filterJobs();
    }
  }

  private initThingCount(countDetails: DeployCountDetail[]): void {
    const total = countDetails.reduce(
      (curTotal: number, countDetail: DeployCountDetail) =>
        curTotal + countDetail.count,
      0,
    );

    this.deployCount = {
      totalCount: total,
      details: countDetails,
    };
  }

  private initThingGroupObservables(): void {
    this.thingGroups$ = this.loadThingGroups$.pipe(
      switchMap(() =>
        this.dataBaseService
          .getFirmwares(this.metaVersion?.getFirmwareIds() ?? [])
          .pipe(
            tap((firmwares) => {
              this.metaVersion?.setFirmwares(firmwares);

              // Verify metaversion signature
              if (!this.metaVersion?.isSigned()) {
                throw new Error('A firmware file is not signed yet!');
              }
            }),
            switchMap(() => {
              if (this.metaVersion) {
                return this.deployService.prepareJob(this.metaVersion);
              }
              return of([]);
            }),
            takeUntil(this.destroy$),
            catchError((err) => this.handleThingGroupsError(err)),
          ),
      ),
      catchError((err) => this.handleThingGroupsError(err)),
      tap(() => (this.preparing = false)),
      shareReplay(1),
    );

    this.thingGroupsExist$ = this.thingGroups$.pipe(
      map((thingGroups) => {
        if (thingGroups.length === 0) {
          return false;
        }
        return thingGroups.every((_tg) => _tg.status === null || 'ACTIVE');
      }),
      shareReplay(1),
    );
  }

  private handleThingGroupsError(
    err: ServiceException,
  ): Observable<ThingGroup[]> {
    console.error(err);
    if (err?.$response?.statusCode === 404) {
      this.showBlockingModalNoThingGroupExist();
    } else {
      if (this.preparing) {
        this.notif.showError(err.message, err);
      } else {
        this.notif.showInfo(err.message);
      }
    }

    return of([]);
  }

  doDeploy(
    options: CreateDeploymentOptions,
  ): Observable<CreateDeploymentResponse | undefined> {
    if (!this.metaVersion) {
      this.deploying = false;
      return of(undefined);
    }

    // Upgrade devices by starting the job in case of confirmation
    return this.deployService.startJob(this.metaVersion, options).pipe(
      map((res: CreateDeploymentResponse): CreateDeploymentResponse => {
        if (!res?.jobId || !this.metaVersion?.id) {
          throw new Error('Missing input data');
        }

        this.notif.showSuccess(
          `Deployment in progress, Job id is ${res?.jobId}`,
        );

        return res;
      }),
    );
  }
}
