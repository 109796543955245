<div class="modal-body text-black">
  <h2>Targeted deployment</h2>
  <hr>
  <p>
    You can deploy this metaversion specifically on devices which match a specific Firmware version
  </p>
  <p>
    You can enter several Firmware versions :
  </p>
  <form [formGroup]="formGroup" class="d-grid gap-3">
    <div class="row" *ngFor="let row of rows; let index = index" [formGroupName]="index+''">
      <div class="col">
        <label [for]="index+'.version'" class="form-label">Version</label>
        <input class="form-control" formControlName="version" [id]="index+'.version'">
      </div>
      <div class="col">
        <label [for]="index+'.type'" class="form-label">Firmware Type</label>
        <select class="form-control form-select" formControlName="type" [id]="index+'.type'">
          <option value="ui" selected>UI</option>
          <option value="wifi">WIFI</option>
        </select>
      </div>
      <div class="col-auto align-self-end" *ngIf="rows.length > 1">
        <button class="btn btn-danger fa fa-trash btn-sm" (click)="removeRow(index)"></button>
      </div>
    </div>
  </form>
  <div class="mt-4">
    <button class="btn btn-primary" (click)="addRow()"><i class="fa fa-plus-square"></i> Add another version</button>
  </div>
  <hr />
  <div class="d-flex justify-content-end gap-2">
    <button (click)="cancel()" class="btn btn-secondary">
      Cancel
    </button>
    <button (click)="confirm()" class="btn btn-primary" [disabled]="formGroup.invalid">
      Continue
    </button>
  </div>
</div>
