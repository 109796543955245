import { inject, Injectable } from '@angular/core';

import {
  Job,
  JobExecutionSummary,
  ListJobsCommandInput,
  ListJobsCommandOutput,
} from '@aws-sdk/client-iot';
import { JobExecutionStatus } from '@aws-sdk/client-iot/dist-types/models/models_1';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import CONFIG from '../../config';
import { AwsService } from '../lib/aws.service';
import { FirmwareDistributionResponse } from '../models/backend/deployment/firmware-distribution';
import {
  GetExecutionsResponse,
  GetExecutionsResponseWithStringDates,
  JobExecutionSummaryForJobAndStatistics,
} from '../models/backend/deployment/get-executions';
import { GroupOfThings } from '../models/Group-of-things.model';
import { MetaVersionJob } from '../models/meta-version-job.model';
import { DeploymentsService } from './backend/services/deployments/deployments.service';
import { DatabaseService } from './database.service';
import { GroupsOfThingsService } from './groups-of-things.service';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  private readonly awsService: AwsService = inject(AwsService);
  private readonly groupsService: GroupsOfThingsService = inject(
    GroupsOfThingsService,
  );
  private readonly databaseService: DatabaseService = inject(DatabaseService);
  private readonly deploymentsService: DeploymentsService =
    inject(DeploymentsService);

  listJobs(params: ListJobsCommandInput): Observable<ListJobsCommandOutput> {
    return from(this.awsService.iot()).pipe(
      switchMap((iot) =>
        iot.listJobs({
          maxResults: 250,
          ...params,
        }),
      ),
    );
  }

  async getJob(jobId: string): Promise<Job> {
    return await lastValueFrom(this.deploymentsService.getDeployment(jobId));
  }

  async cancelJob(jobId: string): Promise<void> {
    return await lastValueFrom(this.deploymentsService.cancelDeployment(jobId));
  }

  async getJobDocument(jobId: string): Promise<string> {
    const res = await (await this.awsService.iot()).getJobDocument({ jobId });
    if (!res.document) {
      throw new Error('Missing job document!');
    }
    return res.document;
  }

  async listDevicesForJob(
    jobId: string,
    status?: JobExecutionStatus,
    nextToken?: string,
  ): Promise<GetExecutionsResponse> {
    return await lastValueFrom(
      this.deploymentsService.getExecutions(jobId, status, nextToken),
    ).then((res) => {
      return this.convertStringDatesToDates(res);
    });
  }

  /**
   * Converts the string dates in the response to Date objects
   * because the API returns them as strings (json serialization)
   * @param res
   */
  convertStringDatesToDates(
    res: GetExecutionsResponseWithStringDates,
  ): GetExecutionsResponse {
    const convertedResponse: GetExecutionsResponse = {
      jobId: res.jobId,
      nextToken: res.nextToken,
      executionSummaries: [],
    };

    const convertedExecutionSummaries = res.executionSummaries.map((e) => {
      // transform string dates to Date objects, by also changing the type of the summary
      const summaryWithStringDates = e.jobExecutionSummary;
      const summaryWithDates =
        e.jobExecutionSummary as unknown as JobExecutionSummary;

      if (summaryWithStringDates) {
        summaryWithDates.lastUpdatedAt = summaryWithStringDates.lastUpdatedAt
          ? new Date(summaryWithStringDates.lastUpdatedAt)
          : undefined;
        summaryWithDates.queuedAt = summaryWithStringDates.queuedAt
          ? new Date(summaryWithStringDates.queuedAt)
          : undefined;
        summaryWithDates.startedAt = summaryWithStringDates.startedAt
          ? new Date(summaryWithStringDates.startedAt)
          : undefined;
      }

      return {
        thingArn: e.thingArn,
        jobExecutionSummary: summaryWithDates,
        statistics: e.statistics,
      } as JobExecutionSummaryForJobAndStatistics;
    });

    convertedResponse.executionSummaries.push(...convertedExecutionSummaries);

    return convertedResponse;
  }

  /**
   * Fetches the groupId associated with a job in dynamo.
   * If the group still exists, also fetches its details.
   *
   * @param jobId the job id to look for
   * @return <ul><li> null if no group is associated with this job</li>
   *  <li>a string containing `[deleted]{groupName}` if the group no longer exists</li>
   *  <li>The group details if a linked group exists</li></ul>
   */
  public getGroupForJob(
    jobId: string,
  ): Observable<GroupOfThings | string | null> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.get({
          TableName: CONFIG.metaversionJobsTable,
          Key: { jobId },
        }),
      ),
      switchMap((response) => {
        if (
          !response.Item?.groupId ||
          typeof response.Item.groupId !== 'string'
        ) {
          // No group, return null
          return of(null);
        }

        if (response.Item.groupId.startsWith('[deleted]')) {
          // DeletedGroup, return the name stored in groupId field
          return of(response.Item.groupId);
        }

        // Existing group, fetch its details
        return this.groupsService.getGroup(response.Item.groupId);
      }),
    );
  }

  /**
   * Returns the MetaversionJob associated with the given job
   * @param jobId the job id associated to the metaversion
   */
  getMetaversionForJob(jobId: string): Promise<MetaVersionJob | undefined> {
    return lastValueFrom(
      this.databaseService
        .getMetaversionForJobs([jobId])
        .pipe(
          map(
            (metaVersionJobs: MetaVersionJob[]): MetaVersionJob =>
              metaVersionJobs[0],
          ),
        ),
    );
  }

  /**
   * Returns the firmware distribution for any given Metaversion (through its Job id)
   * The distribution is given by its
   * @returns the firmware distribution
   */
  getFirmwareDistribution(
    jobId: string,
  ): Promise<FirmwareDistributionResponse> {
    return lastValueFrom(
      this.deploymentsService.getFirmwareDistribution(jobId),
    );
  }
}
