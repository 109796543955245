<div class="modal-body text-black">
  <h2>Progressive Rollout</h2>
  <div class="row gap-2">
    <div class="col">
      <h5 *ngIf="abortConfigToggle">Rollout Configuration</h5>
      <div class="alert alert-secondary">
        <strong>You can choose to roll out the firmware progressively.</strong><br>
        <ul>
          <li>This allows to queue an update over a period of time, rather than all at once as with a classic deployment.</li>
          <li>Failed deployments or faulty firmware can be monitored on a smaller set of devices instead of the entire fleet.</li>
        </ul>
      </div>
      <form [formGroup]="rolloutForm" class="form form-group d-grid gap-3">
        <div>
          <label for="maximumPerDay" class="form-label col-form-label col-form-label-sm">Number of devices per day</label>
          <input class="form-control form-control-sm" formControlName="maximumPerDay" id="maximumPerDay" placeholder="e.g. 2000" type="number" step="1">
          <div class="text-danger mt-1" *ngIf="rolloutForm.get('maximumPerDay')?.errors as errors">
            <span *ngIf="errors.min">Minimum is 1,440 due to technical constraints.</span>
            <span *ngIf="errors.max">Value can't exceed 1,440,000 due to technical constraints.</span>
          </div>
          <small class="text-muted">A classic rollout is equivalent to 1,440,000 devices per day</small>
        </div>
        <div class="d-flex gap-1">
          <button (click)="setToMinimum()" class="btn btn-success btn-sm">
            set to minimum
          </button>
          <button (click)="setToTotalCount()" *ngIf="MINIMUM_DEVICES_PER_DAY < totalCount" class="btn btn-primary btn-sm">
            set to {{totalCount}}
          </button>
        </div>
      </form>
      <p *ngIf="totalCount" class="alert alert-info">
        The progressive rollout will queue the <strong>{{totalCount}}</strong> devices over <span class="badge badge-primary">{{computeEstimatedTime()}}</span>
      </p>
    </div>
    <ng-container *ngIf="abortConfigToggle">
      <div class="vr m-0 p-0"></div>
      <div class="col">
        <h5>Automatic Cancellation Configuration</h5>
        <div class="alert alert-secondary">
          <strong>For improved security, it is possible to automatically cancel the rollout under certain conditions:</strong><br>
          <ul>
            <li><strong>Minimum Devices Deployed:</strong> This is the threshold at which the cancellation check is enabled.</li>
            <li><strong>Failed Threshold:</strong> This is the percentage of failed deployments that will trigger the cancellation.</li>
          </ul>
        </div>
        <form [formGroup]="abortForm" class="form form-group gap-3 row mb-4">
          <div class="col">
            <label for="minimumDevicesDeployed" class="form-label col-form-label col-form-label-sm">Minimum devices notified</label>
            <input class="form-control form-control-sm" formControlName="minimumDevicesDeployed" id="minimumDevicesDeployed" placeholder="e.g. 100" type="number" step="1">
            <div class="text-danger mt-1" *ngIf="abortForm.get('minimumDevicesDeployed')?.errors as errors">
              <span *ngIf="errors.min">A minimum of 1 device is mandatory.</span>
              <span *ngIf="errors.max">Can't exceed the total number of devices that will receive this update: <strong>{{totalCount}}</strong></span>
            </div>
          </div>
          <div class="col">
            <label for="thresholdPercentage" class="form-label col-form-label col-form-label-sm">Failed Threshold</label>
            <div class="input-group input-group-sm">
              <input class="form-control form-control-sm" formControlName="thresholdPercentage" id="thresholdPercentage" placeholder="e.g. 5" type="number" step="1">
              <span class="input-group-text inputGroup-sizing-sm">%</span>
            </div>
            <div class="text-danger mt-1" *ngIf="abortForm.get('thresholdPercentage')?.errors as errors">
              <span *ngIf="errors.min">A minimum of 1% is mandatory since some devices will always fail (timeout, rejected, etc).</span>
              <span *ngIf="errors.max">100% maximum.</span>
            </div>
          </div>
        </form>
        <div *ngIf="abortConfigToggle && abortForm.valid" class="alert alert-info">
          The rollout will <strong>Automatically Cancel</strong> if
          <ul>
            <li>At least <span class="badge badge-primary">{{abortForm.value.minimumDevicesDeployed}} devices</span> have been notified of the update</li>
            <li>At least <span class="badge badge-primary">{{abortForm.value.thresholdPercentage}}%</span> of them <span class="badge badge-danger">FAILED</span> the update.</li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-switch mt-4" [ngClass]="{
        'text-muted': disableAbortConfig,
      }">
        <input class="form-check-input" type="checkbox" role="switch" id="toggleAbortConfig" [(ngModel)]="abortConfigToggle" (change)="toggledAbortConfig()" [disabled]="disableAbortConfig">
        <label class="form-check-label" for="toggleAbortConfig">Add an <strong>Automatic Cancellation</strong> configuration</label>
      </div>
      <div class="form-text text-warning-emphasis" *ngIf="disableAbortConfig">Automatic Cancellation is not compatible with Targeted deployments with over 100 devices.</div>
    </div>
  </div>
  <hr />
  <div class="d-flex justify-content-end gap-2">
    <button (click)="cancel()" class="btn btn-secondary">
      Cancel
    </button>
    <button (click)="confirm()" class="btn btn-primary" [disabled]="rolloutForm.invalid">
      Start Rollout
    </button>
  </div>
</div>
