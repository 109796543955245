export function thingTypeFromThingName(thingName: string): string {
  const TYING_TYPE = 'thingType';
  const regex = new RegExp(`^(?<${TYING_TYPE}>\\w+)-.*$`);
  const thingType = regex.exec(thingName)?.groups?.[TYING_TYPE];

  if (!thingType) {
    throw `Bad input, thingName must be of format "**-**".\nthingName: ${thingName}`;
  }

  return thingType;
}
