import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {
  FirmwareDistributionChartData,
  FirmwareDistributionTotals,
} from '../../models/firmware-distribution';

@Component({
  selector: 'app-firmware-distribution',
  standalone: true,
  imports: [BaseChartDirective, NgIf],
  templateUrl: './firmware-distribution.component.html',
  styleUrl: './firmware-distribution.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirmwareDistributionComponent {
  readonly _chartOptions: ChartOptions = {
    doughnut: {},
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  totals = input<FirmwareDistributionTotals>({ wifi: 0, ui: 0 });

  chartDataUi = input<FirmwareDistributionChartData>({
    labels: [],
    datasets: [],
  });

  chartDataWifi = input<FirmwareDistributionChartData>({
    labels: [],
    datasets: [],
  });
}
