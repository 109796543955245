import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { AuditService } from '../api/backend/services/audit/audit.service';
import { DatabaseService } from '../api/database.service';
import { ThingTypesService } from '../api/thing-types.service';
import { NotificationService } from '../shared/notification.service';
import { SearchableFirmware } from './SearchableFirmware';
import { VersionFlag } from '@common/models/version-flag.enum';

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.css'],
})
export class FirmwaresComponent implements OnInit, OnDestroy {
  protected readonly VersionFlag = VersionFlag;

  firmwares: SearchableFirmware[] = [];
  isLoading = false;

  thingTypes$?: Observable<string[]>;

  activeFirmwaresControl = new UntypedFormControl(true);
  globalSearchInput = new UntypedFormControl('');
  localFiltersFormGroup = new UntypedFormGroup({
    dateStr: new UntypedFormControl(),
    thingType: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    id: new UntypedFormControl(),
    criteriaType: new UntypedFormControl(null),
    versionFlag: new FormControl(null),
    brandAreaStr: new UntypedFormControl(),
    createdBy: new UntypedFormControl(),
  });

  columns: Columns[] = [
    { key: 'dateStr', title: 'Creation Date', width: '7%' },
    { key: 'thingType', title: 'Thing Type', width: '5%' },
    { key: 'type', title: 'Firmware Type', width: '5%' },
    { key: 'id', title: 'Firmware Files' },
    { key: 'criteriaType', title: 'Criteria', width: '5%' },
    { key: 'versionFlag', title: 'Official', width: '5%' },
    { key: 'brandAreaStr', title: 'Brand Area', width: '5%' },
    { key: 'createdBy', title: 'Creator', width: '5%' },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '2%',
    },
  ];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 10,
  };

  versionFlags = Object.values(VersionFlag);

  private destroyed$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly thingTypesService: ThingTypesService,
    private readonly databaseService: DatabaseService,
    private readonly auditService: AuditService,
    private readonly notif: NotificationService,
  ) {}

  ngOnInit(): void {
    this.setIsLoading(false);

    this.thingTypes$ = this.thingTypesService
      .getThingTypes()
      .pipe(shareReplay(1));

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params?.showInactive === 'true') {
        this.activeFirmwaresControl.setValue(false);
      }
    });

    this.activeFirmwaresControl.valueChanges.subscribe((val) => {
      this.search();
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          showInactive: !val || null,
          page: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    });

    this.search();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  resetFilter(): void {
    this.globalSearchInput.setValue('');
    this.globalSearchInput.markAsPristine();

    // Use timeouts to reset each category of filters after some time to prevent query params conflicts
    setTimeout(() => this.localFiltersFormGroup.reset(), 50);
    setTimeout(() => {
      if (!this.activeFirmwaresControl.value) {
        this.activeFirmwaresControl.setValue(true);
        this.activeFirmwaresControl.markAsPristine();
      }
    }, 100);
  }

  async firmwareUpdateState(enable: boolean, id: string): Promise<void> {
    if (enable) {
      await this.databaseService.activateFirmware(id);
    } else {
      await this.databaseService.deactivateFirmware(id);
    }
    this.auditService.pushEvent({
      action: enable ? AuditAction.RESTORE : AuditAction.DEPRECATE,
      type: AuditType.FIRMWARE,
      resourceId: id,
    });
    this.activeFirmwaresControl.setValue(enable);
  }

  setIsLoading(val: boolean): void {
    this.isLoading = val;
    if (this.configuration) {
      this.configuration.isLoading = val;
    }
  }

  /**
   * Allows IDEs to correctly infer the object type to provide completion and checks in HTML template
   */
  typedSearchableFirmware(firmware: SearchableFirmware): SearchableFirmware {
    return firmware;
  }

  private async search(): Promise<void> {
    this.firmwares = [];
    this.setIsLoading(true);
    await this.databaseService
      .listFirmwares(this.activeFirmwaresControl.value ? 1 : 0)
      ?.then(async (res) => {
        const resourceIds = res.map((_f) => _f.id);
        const latestEvents = await lastValueFrom(
          this.auditService.getLatestEvent(
            AuditType.FIRMWARE,
            AuditAction.UPLOAD,
            resourceIds,
          ),
        );
        this.firmwares = res.map((_f) =>
          SearchableFirmware.fromFirmware(_f, latestEvents?.[_f.id]),
        );
      })
      .catch((err) => {
        this.notif.showError(err.message, err);
      });
    this.setIsLoading(false);
  }
}
