import { KeyValuePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { VersionFlag } from '@common/models/version-flag.enum';
import { Firmware } from '../../models/firmware';
import { DisplayVersionFlagPipe } from '../../shared/pipes/display-version-flag.pipe';

@Component({
  selector: 'app-update-version-flag',
  standalone: true,
  imports: [DisplayVersionFlagPipe, KeyValuePipe, ReactiveFormsModule],
  templateUrl: './update-version-flag.component.html',
  styleUrl: './update-version-flag.component.scss',
})
export class UpdateVersionFlagComponent {
  @Input({ required: true }) isLoading!: boolean;
  @Input({ required: true }) patchVersionFlagFC!: FormControl<
    VersionFlag | undefined
  >;
  @Input({ required: true }) firmware!: Firmware;
  @Input({ required: true }) disableButton!: boolean;
  @Input({ required: true }) actionOnClick!: () => void;

  protected readonly VersionFlag = VersionFlag;
}
