import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ListJobsCommandInput,
  ListJobsCommandOutput,
} from '@aws-sdk/client-iot';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { combineLatest, merge, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuditService } from '../api/backend/services/audit/audit.service';
import { DatabaseService } from '../api/database.service';
import { JobsService } from '../api/jobs.service';
import { ThingTypesService } from '../api/thing-types.service';
import { UtilsService } from '../lib/utils.service';
import { Job } from '../models/job';
import { MetaVersionJob } from '../models/meta-version-job.model';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-deployements',
  templateUrl: './deployements.component.html',
  styleUrls: ['./deployements.component.scss'],
})
export class DeployementsComponent implements OnInit {
  jobs$?: Observable<Job[]>;

  readonly columns = [
    { key: 'createdAtStr', title: 'Creation Date', width: '8%' },
    { key: 'jobId', title: 'Job name' },
    { key: 'metaversionId', title: 'Metaversion' },
    { key: 'singleOrMulti', title: 'Target', orderEnabled: false, width: '6%' },
    { key: 'thingType', title: 'Thing type', width: '6%' },
    { key: 'status', title: 'Status', width: '6%' },
    { key: 'createdBy', title: 'Creator', width: '6%' },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '3%',
    },
  ] as Columns[];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 10,
    orderEnabled: true,
  };

  thingTypes$?: Observable<string[]>;

  formGroupGlobalFilters = new UntypedFormGroup({
    inProgressOnly: new UntypedFormControl(false),
  });

  formGroupLocalFilters = new UntypedFormGroup({
    createdAtStr: new UntypedFormControl(),
    jobId: new UntypedFormControl(),
    metaversionId: new UntypedFormControl(),
    singleOrMulti: new UntypedFormControl(null),
    thingType: new UntypedFormControl(),
    status: new UntypedFormControl(),
    createdBy: new UntypedFormControl(),
  });

  filterLoading = false;

  constructor(
    private readonly jobsService: JobsService,
    private readonly databaseService: DatabaseService,
    private readonly utilsService: UtilsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly notif: NotificationService,
    private readonly thingTypesService: ThingTypesService,
    private readonly auditService: AuditService,
  ) {}

  ngOnInit(): void {
    const filterJobs$: Observable<ListJobsCommandInput> =
      this.formGroupGlobalFilters.valueChanges.pipe(
        tap(() => {
          this.filterLoading = true;
          // Save the filters value to query params
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              inProgressOnly:
                this.formGroupGlobalFilters.value.inProgressOnly || null,
              page: null,
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }),
        map((formGroupValue) => ({
          status: formGroupValue.inProgressOnly ? 'IN_PROGRESS' : undefined,
        })),
      );

    this.jobs$ = merge(filterJobs$, this.getRequestFromQueryParams$()).pipe(
      switchMap((request) => this.getListJobs$(request)),
      map((jobsResponse: ListJobsCommandOutput) =>
        this.parseAndSortJobs(jobsResponse),
      ),
      tap(() => (this.filterLoading = false)),
      shareReplay(1),
      switchMap((jobs) => this.getMetaversions$(jobs)),
    );

    this.thingTypes$ = this.thingTypesService
      .getThingTypes()
      .pipe(shareReplay(1));
  }

  /**
   * Allows IDEs to correctly infer the object type to provide completion and checks in HTML template
   */
  typedJobObject(job: Job): Job {
    return job;
  }

  resetFilters(): void {
    this.formGroupLocalFilters.reset();

    // Use timeouts to reset each category of filters after some time to prevent query params conflicts
    setTimeout(() => {
      if (this.formGroupGlobalFilters.value.inProgressOnly) {
        this.formGroupGlobalFilters.setValue({ inProgressOnly: false });
      }
    }, 50);
  }

  private getMetaversions$(jobs: Job[]): Observable<Job[]> {
    const resourceIds = jobs.map((j) => j.jobId);
    return combineLatest([
      this.auditService.getLatestEvent(
        AuditType.DEPLOYMENT,
        AuditAction.START,
        resourceIds,
      ),
      this.databaseService.getMetaversionForJobs(resourceIds),
    ]).pipe(
      map(([logs, metaversions]) => {
        const mapByJobId: { [jobId: string]: MetaVersionJob } = {};
        metaversions.forEach((mv) => (mapByJobId[mv.jobId] = mv));

        return jobs.map((job) => {
          const metaVersionJob = mapByJobId[job.jobId];
          return job
            .addMetaversionId(metaVersionJob?.metaversionId)
            .addSingleOrMulti(metaVersionJob?.jobType)
            .addCreatedBy(logs?.[job.jobId]?.userId)
            .addVersionFilterCounter(metaVersionJob?.targetedVersions);
        });
      }),
      catchError((err) => {
        this.notif.showError(
          err?.message ??
            'An error occurred while fetching the deployment/metaversion associations',
          err,
        );
        return of(jobs);
      }),
    );
  }

  private parseAndSortJobs(jobsResponse: ListJobsCommandOutput): Job[] {
    return Job.fromItemList(jobsResponse?.jobs ?? []).sort((a, b) =>
      this.utilsService.compare(a.createdAt, b.createdAt, false),
    );
  }

  private getListJobs$(
    request: ListJobsCommandInput,
  ): Observable<ListJobsCommandOutput> {
    return this.jobsService.listJobs(request).pipe(
      catchError((err) => {
        this.notif.showError(err.message, err);
        return of({ jobs: [], $metadata: {} });
      }),
    );
  }

  private getRequestFromQueryParams$(): Observable<ListJobsCommandInput> {
    return this.route.queryParams?.pipe(
      take(1),
      map((params: Params) => {
        const inProgressOnly = params?.inProgressOnly === 'true';
        this.formGroupGlobalFilters
          .get('inProgressOnly')
          ?.setValue(inProgressOnly ?? false, { emitEvent: false });

        return {
          status: inProgressOnly ? 'IN_PROGRESS' : undefined,
        };
      }),
    );
  }
}
