export enum FeatureGroupEnum {
  BASE_ROLE = 'BaseRole',
  SEE_THINGS_HOME = 'SeeThingsHome',
  SEE_CONNECTED_THINGS = 'SeeConnectedThings',
  SEE_THING_DETAILS = 'SeeThingDetails',
  SEARCH_THING = 'SearchThing',
  DELETE_THING = 'DeleteThing',
  DELETE_NEXTFIRMWARE_SHADOW = 'DeleteNextfirmwareShadow',
  LIST_FIRMWARES = 'ListFirmwares',
  LIST_METAVERSIONS = 'ListMetaversions',
  SEE_STATISTICS = 'SeeStatistics',
  SEE_FIRMWARE_DETAILS = 'SeeFirmwareDetails',
  EDIT_FIRMWARE = 'EditFirmware',
  SEE_METAVERSION_DETAILS = 'SeeMetaversionDetails',
  DEPRECATE_METAVERSION = 'DeprecateMetaversion',
  CREATE_EDIT_METAVERSION = 'CreateEditMetaversion',
  LIST_DEPLOYMENTS = 'ListDeployments',
  SEE_DEPLOYMENT_DETAILS = 'SeeDeploymentDetails',
  SEE_BILLING = 'SeeBilling',
  LIST_GROUP_OF_THINGS = 'ListGroupsOfThings',
  SEE_GROUP_OF_THINGS_DETAILS = 'SeeGroupOfThingsDetails',
  EDIT_GROUP_OF_THINGS = 'EditGroupOfThings',
  DELETE_GROUP_OF_THINGS = 'DeleteGroupOfThings',
  DEPLOY_ON_ONE_THING = 'DeployOnOneThing',
  DEPLOY_WITH_CRITERIA = 'DeployWithCriteria',
  DEPLOY_ON_GROUP_OF_THINGS = 'DeployOnGroupOfThings',
  LIST_FACTORY_FIRMWARES = 'ListFactoryFirmwares',
  SEE_FACTORY_FIRMWARE_DETAILS = 'SeeFactoryFirmwareDetails',
  CREATE_DELETE_FACTORY_FIRMWARE = 'CreateDeleteFactoryFirmware',
  READ_PRODUCT_LOGS = 'ReadProductLogs',
  DELETE_PRODUCT_LOGS = 'DeleteProductLogs',
  ENABLE_PRODUCT_LOGS = 'EnableProductLogs',
  ADMIN_USER_RIGHTS = 'AdminUserRights',
  SEE_MANIFEST = 'SeeManifest',
  EDIT_NICKNAME = 'EditNickname',
  CANCEL_DEPLOYMENT = 'CancelDeployment',
}
