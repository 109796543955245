import { NgForOf, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeployCount } from '../../api/registry.service';
import { RolloutConfirmation } from '../../models/metaversion/confirm-deployment';

@Component({
  selector: 'app-confirm-multi-deployment-dialog',
  standalone: true,
  imports: [NgForOf, NgIf],
  templateUrl: './confirm-multi-deployment-dialog.component.html',
})
export class ConfirmMultiDeploymentDialogComponent {
  protected readonly modal = inject(NgbActiveModal);

  deployCount: DeployCount = { totalCount: 0, details: [] };

  classicDeployment(): void {
    this.modal.close(RolloutConfirmation.CLASSIC);
  }

  progressiveDeployment(): void {
    this.modal.close(RolloutConfirmation.PROGRESSIVE);
  }

  cancel(): void {
    this.modal.close(RolloutConfirmation.CANCEL);
  }
}
