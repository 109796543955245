import { NgForOf, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DeploymentStatistics } from '../../models/backend/thing/deployment-statistics';
import { DisplayFormatDatePipe } from '../pipes/display-format-date.pipe';
import { HumanizeDurationPipe } from '../pipes/humanize-duration.pipe';

@Component({
  selector: 'app-deployment-statistics',
  standalone: true,
  imports: [
    NgbPopover,
    HumanizeDurationPipe,
    NgIf,
    NgForOf,
    DisplayFormatDatePipe,
  ],
  styleUrl: './deployment-statistics.component.scss',
  templateUrl: './deployment-statistics.component.html',
})
export class DeploymentStatisticsComponent {
  @Input() statistics!: DeploymentStatistics;
}
