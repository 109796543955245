<ng-container *ngIf="filteredThingsResult; else loader">
  @if ((filteredThingsResult.totalFiltered ?? 0) > 0) {
    <div class="modal-body text-black"
    >
      <h3>Confirmation</h3>
      <hr>
      <p class="mb-0">Below, the amount of devices which firmware version matches.</p>
      <ul class="list-group list-group-flush">
        <li *ngFor="let _filterCount of filteredThingsResult.filterCounts"
            class="list-group-item d-flex align-items-center gap-2"
        >
          <div>
            <strong class="fs-5">{{ _filterCount.filter.version }}</strong>
            <span class="badge text-bg-light">{{ _filterCount.filter.type }}</span> :
          </div>
          <div>
            <span class="badge text-bg-success"> {{ _filterCount.count }} </span> devices
          </div>
        </li>
      </ul>
      <p class="small text-muted" *ngIf="filteredThingsResult.filterCounts.length > 1">
        Some counts may overlap if both wifi and ui versions have been given and match common devices.
      </p>
      <p>
        Only <strong class="badge text-bg-primary">{{ filteredThingsResult.totalFiltered }}</strong> devices will receive
        this deployment<br>instead of the initial <strong>{{ filteredThingsResult.total }}</strong> fitting
        this Metaversion.
      </p>
      <hr />
      <div class="d-flex justify-content-end gap-2">
        <button (click)="cancel()" class="btn btn-secondary">
          Cancel
        </button>
        <button (click)="confirm()" class="btn btn-primary button-model">
          Deploy on <strong>{{ filteredThingsResult.totalFiltered }}</strong> devices
        </button>
      </div>
    </div>
  } @else {
    <div *ngIf="filteredThingsResult.totalFiltered === 0"
         class="modal-body text-black"
    >
      <h3>No devices found</h3>
      <hr />
      <div class="d-flex justify-content-end">
        <button (click)="cancel()" class="btn btn-secondary">
          Cancel
        </button>
      </div>
    </div>
  }
</ng-container>

<ng-template #loader>
  <div class="modal-body">
    <app-spinner></app-spinner>
  </div>
</ng-template>
