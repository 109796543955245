import { NativeAttributeValue } from '@aws-sdk/lib-dynamodb';
import { BrandAreaKey } from './brandarea';
import { VersionFlag } from '@common/models/version-flag.enum';

export type RangeKey = string & { _: 'RangeKey' };
export type CmmfKey = string & { _: 'CmmfKey' };
export type IndiceKey = string & { _: 'IndiceKey' };
export type CriteriaKey = string & { _: 'CriteriaKey' };

export enum CriteriaType {
  THINGTYPE = 'THINGTYPE',
  RANGE = 'RANGE',
  RANGE_INDICE = 'RANGE_INDICE',
  CMMF = 'CMMF',
  CMMF_INDICE = 'CMMF_INDICE',
}

export interface ExtractedFirmwareDataWrapper {
  tempFilename: string;
  extractedData: ExtractedFirmwareData;
}

export interface ExtractedFirmwareData {
  technicalIncrement?: number;
  version?: string;
}

export interface DeleteNextFirmwareShadowsResponse {
  firmwareId: string;
  count: number;
}

export interface FirmwareFileWrapper {
  file?: File;
  filename?: string;
  sourceFilename?: string;
  sourceBucket?: string;
  targetBucket?: string;
}

export class S3Object {
  [criteriaKey: string]: S3Value;

  constructor(
    range: string | undefined,
    cmmf: string | undefined,
    indice: string | undefined,
    brandArea: string | undefined,
    s3Value: S3Value,
  ) {
    const key = S3Object.buildCriteriaKey(range, cmmf, indice, brandArea);
    this[key] = s3Value;
  }

  public static buildCriteriaKey(
    range?: string,
    cmmf?: string,
    indice?: string,
    brandArea?: string,
  ): CriteriaKey {
    return [
      range || 'NA',
      cmmf || 'NA',
      indice || 'NA',
      brandArea || 'NA',
    ].join('_') as CriteriaKey;
  }
}

export class S3Value {
  public file: string;
  public isSigned: boolean;
  public bootloader?: string;
  public isAlreadySigned?: boolean;
  public errorMessage?: string;
  public date: string;

  constructor(
    file: string,
    isSigned: boolean,
    isAlreadySigned?: boolean,
    bootloader?: string,
    errorMessage?: string,
    date?: string,
  ) {
    this.file = file;
    this.isSigned = isSigned;
    this.isAlreadySigned = isAlreadySigned;
    this.bootloader = bootloader;
    this.errorMessage = errorMessage;
    this.date = date || '';
  }
}

export class NewS3Object extends S3Value {
  public brandArea?: BrandAreaKey;
  public range?: RangeKey;
  public cmmf?: CmmfKey;
  public indice?: IndiceKey;

  constructor(
    range: RangeKey | undefined,
    cmmf: CmmfKey | undefined,
    indice: IndiceKey | undefined,
    brandArea: BrandAreaKey | undefined,
    file: string,
    isSigned: boolean,
    isAlreadySigned?: boolean,
    bootloader?: string,
    errorMessage?: string,
  ) {
    super(file, isSigned, isAlreadySigned, bootloader, errorMessage);
    this.range = range;
    this.cmmf = cmmf;
    this.indice = indice;
    this.brandArea = brandArea;
  }

  public static empty(): NewS3Object {
    return new NewS3Object(
      undefined,
      undefined,
      undefined,
      undefined,
      '',
      false,
    );
  }

  public clone(): NewS3Object {
    return new NewS3Object(
      this.range,
      this.cmmf,
      this.indice,
      this.brandArea,
      this.file,
      this.isSigned,
      this.isAlreadySigned,
      this.bootloader,
      this.errorMessage,
    );
  }

  public getS3Object(): S3Object {
    return new S3Object(
      this.range,
      this.cmmf,
      this.indice,
      this.brandArea,
      new S3Value(
        this.file,
        this.isSigned,
        this.isAlreadySigned,
        this.bootloader,
        this.errorMessage,
        new Date().toISOString(),
      ),
    );
  }

  public getCriteriaType(): CriteriaType {
    if (this.range) {
      if (this.indice) {
        return CriteriaType.RANGE_INDICE;
      }
      return CriteriaType.RANGE;
    } else if (this.cmmf) {
      if (this.indice) {
        return CriteriaType.CMMF_INDICE;
      }
      return CriteriaType.CMMF;
    }
    return CriteriaType.THINGTYPE;
  }

  public getCriteriaKey(): CriteriaKey {
    return [
      this.range || 'NA',
      this.cmmf || 'NA',
      this.indice || 'NA',
      this.brandArea || 'NA',
    ].join('_') as CriteriaKey;
  }
}

export class Firmware {
  public id: string;
  public version: string;
  public type: string;
  public thingType: string;
  public s3Key?: S3Object;
  public newS3Key?: NewS3Object;
  public date: string;
  public releaseNote?: string;
  public activated: number;
  public versionFlag?: VersionFlag;

  public criteriaType: CriteriaType = CriteriaType.THINGTYPE;

  constructor(
    id: string,
    version: string,
    type: string,
    thingType: string,
    date: string,
    activated: number,
    s3Key?: S3Object,
    newS3Key?: NewS3Object,
    releaseNote?: string,
    criteriaType?: CriteriaType,
    versionFlag?: VersionFlag,
  ) {
    this.id = id;
    this.version = version;
    this.type = type;
    this.thingType = thingType;
    this.date = date;
    this.s3Key = s3Key;
    this.newS3Key = newS3Key;
    this.releaseNote = releaseNote;
    this.activated = activated;
    if (criteriaType) {
      this.criteriaType = criteriaType;
    }

    if (versionFlag) {
      this.versionFlag = versionFlag;
    }
  }

  public static parse(
    item: Record<string, NativeAttributeValue>,
    initNewS3Key: boolean = false,
  ): Firmware {
    let s3Key: S3Object;

    if (item.s3Key) {
      s3Key = item.s3Key as unknown as S3Object;
    } else {
      const s3Value = new S3Value(
        item.s3Key as string,
        item.isSigned as unknown as boolean,
        item.isAlreadySigned as unknown as boolean,
        item.bootloader as unknown as string,
        item.errorMessage as unknown as string,
      );

      s3Key = { NA: s3Value };
    }

    const newS3Key = initNewS3Key ? NewS3Object.empty() : undefined;
    return new Firmware(
      item.id as unknown as string,
      item.version as unknown as string,
      item.type as unknown as string,
      item.thingType as unknown as string,
      item.date as unknown as string,
      item.activated as unknown as number,
      s3Key,
      newS3Key,
      item.releaseNote ? (item.releaseNote as unknown as string) : undefined,
      item.criteriaType as unknown as CriteriaType,
      item.versionFlag,
    );
  }

  public static empty(): Firmware {
    return new Firmware(
      '',
      '',
      '',
      '',
      '',
      1,
      undefined,
      NewS3Object.empty(),
      undefined,
      CriteriaType.THINGTYPE,
    );
  }

  public clone(): Firmware {
    return new Firmware(
      this.id,
      this.version,
      this.type,
      this.thingType,
      this.date,
      this.activated,
      this.s3Key ? JSON.parse(JSON.stringify(this.s3Key)) : undefined,
      this.newS3Key ? this.newS3Key.clone() : this.newS3Key,
      this.releaseNote,
      this.criteriaType,
      this.versionFlag,
    );
  }

  public getS3CriteriaKeys(): CriteriaKey[] {
    return Object.keys(this.s3Key || []).map((s3key) => s3key as CriteriaKey);
  }

  public getFirmwareBrandArea(): string[] {
    return Object.keys(this.s3Key || [])
      .map(
        (s3key) =>
          ParsedCriteriaKey.fromCriteriaKey(s3key as CriteriaKey).brandArea,
      )
      .filter((_) => _ !== 'NA');
  }

  public getFilename(
    criteriaKey: CriteriaKey = 'NA_NA_NA_NA' as CriteriaKey,
  ): string {
    return this?.s3Key?.[criteriaKey]
      ? this.s3Key[criteriaKey].file.substr(
          this.s3Key[criteriaKey].file.lastIndexOf('/') + 1,
        )
      : '';
  }

  public isAlreadySigned(
    criteriaKey: CriteriaKey = 'NA_NA_NA_NA' as CriteriaKey,
  ): boolean {
    return this?.s3Key?.[criteriaKey]?.isAlreadySigned === true;
  }

  public isSigned(criteriaKey?: string): boolean {
    if (criteriaKey) {
      return this?.s3Key?.[criteriaKey]?.isSigned === true;
    }

    const s3keys = Object.keys(this?.s3Key || {});

    return (
      !!s3keys.length &&
      s3keys.every((s3key) => this.s3Key?.[s3key]?.isSigned === true)
    );
  }

  public getBootloader(
    criteriaKey: CriteriaKey = 'NA_NA_NA_NA' as CriteriaKey,
  ): string {
    return this?.s3Key?.[criteriaKey]
      ? this.s3Key[criteriaKey].bootloader || '-'
      : '-';
  }

  public getSigningError(
    criteriaKey: CriteriaKey = 'NA_NA_NA_NA' as CriteriaKey,
  ): string {
    return this?.s3Key?.[criteriaKey]
      ? this.s3Key[criteriaKey].errorMessage || '-'
      : '-';
  }

  public isNewSigned(): boolean {
    return this?.newS3Key?.isSigned === true;
  }

  public generateId(): string {
    this.id = `${this.thingType}_${this.type}_${this.version}`.toUpperCase();
    return this.id;
  }

  /**
   * Checks every s3Key keys if any *loosely* matches the given "criteriaKey"
   * "Loosely" here means any part of the key (see ParsedCriteriaKey) is either 'NA'
   * or its value is the exact equivalent in the parameter "criteriaKey"
   *
   * @example given the criteriaKey "BC70_NA_1_NA"
   *  the method would return true if an s3Key was like : "NA_NA_NA_NA", "BC70_NA_1_NA", ...
   * @param criteriaKey CriteriaKey the criteria key to check on every s3Key
   * @return boolean true if the firmware has any s3Key which loosely matches the criteria key
   */
  public doesAnyS3CriteriaKeyLooselyMatch(criteriaKey: CriteriaKey): boolean {
    const referenceKey = ParsedCriteriaKey.fromCriteriaKey(criteriaKey);

    return this.getS3CriteriaKeys().some((_s3CriteriaKey) => {
      const _firmwareKey = ParsedCriteriaKey.fromCriteriaKey(_s3CriteriaKey);
      return (
        (_firmwareKey.range === 'NA' ||
          _firmwareKey.range === referenceKey.range) &&
        (_firmwareKey.cmmf === 'NA' ||
          _firmwareKey.cmmf === referenceKey.cmmf) &&
        (_firmwareKey.indice === 'NA' ||
          _firmwareKey.indice === referenceKey.indice) &&
        (_firmwareKey.brandArea === 'NA' ||
          _firmwareKey.brandArea === referenceKey.brandArea)
      );
    });
  }
}

export class ParsedCriteriaKey {
  constructor(
    public range: RangeKey,
    public cmmf: CmmfKey,
    public indice: IndiceKey,
    public brandArea: BrandAreaKey,
  ) {}

  public static fromCriteriaKey(s3Key: CriteriaKey): ParsedCriteriaKey {
    const [range, cmmf, indice, brandArea] = s3Key.split('_');
    return new ParsedCriteriaKey(
      range as RangeKey,
      cmmf as CmmfKey,
      indice as IndiceKey,
      brandArea as BrandAreaKey,
    );
  }

  public valuesAreOnlyNA(): boolean {
    return Object.values(this).every((k) => k === 'NA');
  }
}
