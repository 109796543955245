<span
  class="badge"
  [ngClass]="{
    'd-block': block,
    'badge-primary': jobType === 'SINGLE',
    'badge-info': jobType === 'MULTI',
    'badge-indigo': jobType === 'MULTI_TARGETED',
    'badge-warning': jobType === 'GROUP',
  }"
>
  <span *ngIf="jobType === 'MULTI_TARGETED'; else elseBlock"
        [ngbTooltip]="tooltip" class="pe-auto"
  >
    MULTI (Targeted)
  </span>
  <ng-template #elseBlock>
    {{ jobType | replace: '_':' ' }}
  </ng-template>
</span>


<ng-template #tooltip>
  <span *ngFor="let _versionFilterCount of versionFilterCount">
    <strong>{{_versionFilterCount.filter.version}}</strong>&nbsp;({{_versionFilterCount.filter.type}})&nbsp;:&nbsp;{{_versionFilterCount.count}}&nbsp;devices
  </span>
</ng-template>
